import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiKeyModel} from '../../../../models/api-key-model';
import {ApiKeysService} from '../../../../services/api-keys.service';
import {RoleModel} from '../../../../models/role-model';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-apikey-editor',
  templateUrl: './apikey-editor.component.html',
  styleUrls: ['./apikey-editor.component.css']
})
export class ApikeyEditorComponent implements OnInit {
  public apikey: ApiKeyModel = new ApiKeyModel();
  private subscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apikeyService: ApiKeysService
    ) {
    // Gets the key from the route parameters
    this.subscription = this.route.params.subscribe(params => {
      this.apikeyService.retrieveApiKey(params['key']).then((result: ApiKeyModel) => {
        this.apikey = result;
      });
    });
  }

  ngOnInit() {
  }

  public saveApikey() {
    this.apikeyService.storeApiKey(this.apikey);
    this.router.navigate(['sysadmin']);
  }

  public handleCancel() {
    this.router.navigate(['sysadmin']);
  }

  public HandleDelete() {
    if (this.apikey.hasKey()) {
      this.apikeyService.removeApiKey(this.apikey);
      this.router.navigate(['sysadmin']);
    }
  }
}
