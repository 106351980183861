import { Pipe, PipeTransform } from '@angular/core';
import { EditorModel } from '../models/editor-model';
import { ArticleModel } from '../models/article-model';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Pipe({
  name: 'mayEdit'
})
export class MayeditPipe implements PipeTransform {

  constructor() {

  }
  transform(value: EditorModel, inArticle: ArticleModel): boolean {
    if (value) {
      return this.mayEditByChannel(value, inArticle);
    }
    return false;
  }

  mayEditByChannel(inEditor: EditorModel, inArticle: ArticleModel): boolean {

    if (inEditor.allChannels === true) {
      return true;
    } else if (inArticle.channels.length > 0) {
      return inEditor.channels.some(x => inArticle.channels.indexOf(x) >= 0);
    } else if (inArticle.channels.length === 0) {
      return true;
    }

    return false;



  }



}
