/**
 * DomainDefModel
 *
 * Models the definition of a supported domain (a domain for which users
 * can read private articles
 *
 * The domain part of a users verified email address must match one of the
 * supported domains in order to read private articles
 */
import {isNullOrUndefined} from 'util';

export class DomainDefModel {
  private _key: string = '';
  private _domain: string = '';

  /**
   * fromFirebase)
   *
   * Returns an DomainDefModel constructed from fireBase data
   *
   * @param inFireNode
   * @returns {DomainDefModel}
   */
  public static fromFirebase(inFireNode: any): DomainDefModel {
    const theModel: DomainDefModel = new DomainDefModel();

    if (!isNullOrUndefined(inFireNode.key)) {
      // Store the key to the firebaseData
      theModel._key = inFireNode.key;

      // Retirieve the payload of the firebase data
      const thePayload = inFireNode.payload.val();

      if (!isNullOrUndefined(thePayload.domain)) {
        theModel._domain = thePayload.domain;
      }
    }

    return theModel;
  }

  /**
   * DomainDefModel()
   *
   * @constructor
   */
  public DomainDefModel() {

  }

  /**
   * toFirebase()
   *
   * Returns a fireBase node with data from the object
   *
   * @returns {any}
   */
  public toFirebase(): any {
    const theObj: any = {
      domain: this._domain
    };

    return theObj;
  }

  /**
   * hasKey()
   *
   * Returns true if the model has a key (thus comes from the fireBase databae,
   * and false when no key is present (thus never been saved).
   *
   * @returns {boolean}
   */
  public hasKey(): boolean {
    return !isNullOrUndefined(this._key) && this._key.length > 0;
  }

  /*

  GETTERS AND SETTERS

   */


  get key(): string {
    return this._key;
  }

  set key(value: string) {
    this._key = value;
  }

  get domain(): string {
    return this._domain;
  }

  set domain(value: string) {
    this._domain = value;
  }
}
