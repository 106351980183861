import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SettingsService } from '../../../services/settings.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-allowed-user-list',
  templateUrl: './allowed-user-list.component.html',
  styleUrls: ['./allowed-user-list.component.css']
})
export class AllowedUserListComponent implements OnInit, OnDestroy {
  allowedUserList = [];
  private sub: Subscription;
  constructor(private settingsService: SettingsService, private router: Router) { }

  ngOnInit() {
    this.sub = this.settingsService.getAllowedUsers().subscribe(result => {
 
      this.allowedUserList = result;
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  public deleteUser(key: string) {
    this.settingsService.deleteAllowedUser(key);
  }


  public newAllowedUser() {
    this.router.navigate(['admin/editalloweduser','']);
  }

  public editAllowedUser(inKey: string) {
    this.router.navigate(['admin/editalloweduser', inKey]);
  }
}
