import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { RolesService } from '../../services/roles.service';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  user: firebase.User;
  public logoUrl;
  public search = false;
  @Output() mDrawerEmitter = new EventEmitter<boolean>();
  @Output() mAdminEmitter = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private rolesService: RolesService,
    private authService: AuthService,
    private settingsService: SettingsService) { }

  ngOnInit() {
    this.authService.getLoggedInUser().subscribe(result => {
      this.user = result;
    });

    this.logoUrl = this.settingsService.getLogo();


  }

  /**
   * openDrawer()
   *
   * Emits to the app-component that the sidenav needs to be opened (sidenav.toggle())
   */
  openDrawer() {
    this.mDrawerEmitter.emit(true);
  }

  toggleSearch() {
    this.search = !this.search;
  }
  /**
   * openAdmin()
   *
   *
   */
  openAdmin(inToAdmin) {
    if (inToAdmin) {
      this.router.navigate(['/admin']);
    } else {
      this.router.navigate(['/']);
    }
  }

  /**
   * openSysAdmin()
   *
   *
   * @param inToAdmin
   */
  openSysAdmin(inToAdmin) {
    if (inToAdmin) {
      this.router.navigate(['/sysadmin']);
    } else {
      this.router.navigate(['/']);
    }
  }
}
