import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {RolesService} from '../services/roles.service';
import {AuthService} from '../services/auth.service';
import {map, mergeMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(private rolesService: RolesService, private authService: AuthService, private router: Router) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    // TODO: check this method later again if this is right
    /**
     * Checks if user is an editor, if so, then it may activate the editor component
     *
     */
    return this.authService.getLoggedInUser().pipe(
      mergeMap(result => {
        if (result) {
          return this.rolesService.detectAdmin(result.email, '', '', '').pipe(
            map(isEditor => {
              if (isEditor) {
                return true;
              } else {
                this.router.navigate(['admin']);
              }
            })
          );
        }
      }));

  }
}
