import {isNullOrUndefined} from "util";

export class CloudTriggerModel {
  private _key: string = '';                // The fireBase key for this article
  private _action: string = '';             // the action to be taken
  private _params: Array<string> = [];      // Optional parameters for the action

  /**
   * fromFirebase()
   *
   * Returns an CloudTriggerModel constructed from fireBase data
   *
   * @param inFireNode
   * @returns {CloudTriggerModel}
   */
  public static fromFirebase(inFireNode: any): CloudTriggerModel {
    // Create a new empty model
    const theModel: CloudTriggerModel = new CloudTriggerModel();

    if (!isNullOrUndefined(inFireNode.key)) {
      // Store the key to the firebaseData
      theModel._key = inFireNode.key;

      // Retrieve the payload of the firebase data
      const thePayload = inFireNode.payload.val();

      // Decode the node elements
      if (!isNullOrUndefined(thePayload.action)) {
        theModel._action = thePayload.action;
      }

      if (!isNullOrUndefined(thePayload.params)) {
        theModel._params = thePayload.params;
      }
    }

    return theModel;
  }

  /**
   * CloudTriggerModel()
   *
   * @constructor
   */
  public CloudTriggerModel() {

  }

  /**
   * toFirebase()
   *
   * Returns a fireBase node with data from the object
   *
   * @returns {any}
   */
  public toFirebase(): any {
    return {
      action: this._action,
      params: this._params
    };
  }

  /**
   * hasKey()
   *
   * Returns true if the model has a key (thus comes from the fireBase database,
   * and false when no key is present (thus never been saved).
   *
   * @returns {boolean}
   */
  public hasKey(): boolean {
    return !isNullOrUndefined(this._key) && this._key.length > 0;
  }

  /*

GETTERS AND SETTERS

 */

  get key(): string {
    return this._key;
  }

  set key(value: string) {
    this._key = value;
  }

  get action(): string {
    return this._action;
  }

  set action(value: string) {
    this._action = value;
  }

  get params(): Array<string> {
    return this._params;
  }

  set params(value: Array<string>) {
    this._params = value;
  }
}
