import {isNullOrUndefined} from 'util';

export class ApiKeyModel {
  private _key: string = '';                  // fireBase key for this node
  private _apiKey: string = '';               // The content of the article (an html string)
  private _name: string = '';                 // The name of the api key (name of the calling app)
  private _pushUrl: string = '';              // URL to push data to when articles change
  private _pushArticleLimit: number = 5;      // Maximum number of articles pushed per channels
  private _pushAllChannels: boolean = true;   // Should all channels be pushed
  private _pushChannels: Array<string> = [];  // Array of channels to push to
  private _pushKey: string = '';              // key to use when pushing data
  private _viewUrl: string = '';              // Url used to view an article

  /**
   * fromFirebase()
   *
   * Returns an ApiKeyModel constructed from fireBase data
   *
   * @param inFireNode          any   data as received from fireBase
   * @returns {ApiKeyModel}
   */
  public static fromFirebase(inFireNode: any): ApiKeyModel {
    // Create a new empty model
    const theModel: ApiKeyModel = new ApiKeyModel();

    if (!isNullOrUndefined(inFireNode.key)) {
      // Store the key to the firebaseData
      theModel._key = inFireNode.key;

      // Retirieve the payload of the firebase data
      const thePayload = inFireNode.payload.val();

      // Decode the node elements
      if (!isNullOrUndefined(thePayload.apikey)) {
        theModel._apiKey = thePayload.apikey;
      }
      if (!isNullOrUndefined(thePayload.name)) {
        theModel._name = thePayload.name;
      }
      if (!isNullOrUndefined(thePayload.pushUrl)) {
        theModel._pushUrl = thePayload.pushUrl;
      }
      if (!isNullOrUndefined(thePayload.pushArticleLimit)) {
        theModel._pushArticleLimit = thePayload.pushArticleLimit;
      }
      if (!isNullOrUndefined(thePayload.pushAllChannels)) {
        theModel._pushAllChannels = thePayload.pushAllChannels;
      }
      if (!isNullOrUndefined(thePayload.pushChannels)) {
        theModel._pushChannels = thePayload.pushChannels;
      }
      if (!isNullOrUndefined(thePayload.pushKey)) {
        theModel._pushKey = thePayload.pushKey;
      }
      if (!isNullOrUndefined(thePayload.viewUrl)) {
        theModel._viewUrl = thePayload.viewUrl;
      }
    }

    return theModel;
  }

  /**
   * ContentModel()
   *
   * @constructor
   */
  public ApiKeyModel() {

  }

  /**
   * toFirebase()
   *
   * Returns a fireBase node with data from the object
   *
   * @returns {any}
   */
  public toFirebase(): any {
    const theObj: any = {
      apikey:             this._apiKey,
      name:               this._name,
      pushUrl:            this._pushUrl,
      pushArticleLimit:   this._pushArticleLimit,
      pushAllChannels:    this._pushAllChannels,
      pushChannels:       this._pushChannels,
      pushKey:            this._pushKey,
      viewUrl:            this._viewUrl
    };

    return theObj;
  }

  /**
   * hasKey()
   *
   * Returns true if the model has a key (thus comes from the fireBase databae,
   * and false when no key is present (thus never been saved).
   *
   * @returns {boolean}
   */
  public hasKey(): boolean {
    return !isNullOrUndefined(this._key) && this._key.length > 0;
  }

  /*

  GETTERS AND SETTERS

 */


  get key(): string {
    return this._key;
  }

  set key(value: string) {
    this._key = value;
  }

  get apiKey(): string {
    return this._apiKey;
  }

  set apiKey(value: string) {
    this._apiKey = value;
  }


  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }


  get pushUrl(): string {
    return this._pushUrl;
  }

  set pushUrl(value: string) {
    this._pushUrl = value;
  }

  get pushArticleLimit(): number {
    return this._pushArticleLimit;
  }

  set pushArticleLimit(value: number) {
    this._pushArticleLimit = value;
  }

  get pushAllChannels(): boolean {
    return this._pushAllChannels;
  }

  set pushAllChannels(value: boolean) {
    this._pushAllChannels = value;
  }

  get pushChannels(): Array<string> {
    return this._pushChannels;
  }

  set pushChannels(value: Array<string>) {
    this._pushChannels = value;
  }

  get pushKey(): string {
    return this._pushKey;
  }

  set pushKey(value: string) {
    this._pushKey = value;
  }

  get viewUrl(): string {
    return this._viewUrl;
  }

  set viewUrl(value: string) {
    this._viewUrl = value;
  }
}
