/**
 * ChannelLinkModel
 *
 * Models a channel link, a link between a channelDef and an article
 *
 * Articles can be published through one or more channels (channelDefs).
 * The link between a channelDef and an article is maintained in the channels
 *
 * The member _channelName is NOT read from the channelNode in fireBase, but should be
 * read from the channelDef node (since the name may be changing
 */
import {isNullOrUndefined} from 'util';

export class ChannelLinkModel {
  private _key: string;
  private _articleKey: string;
  private _channelDefKey: string;

  private _channelName: string;

  /**
   * fromFirebase)
   *
   * Returns an ChannelLinkModel constructed from fireBase data
   *
   * @param inFireNode
   * @returns {ChannelModel}
   */
  public static fromFirebase(inFireNode: any): ChannelLinkModel {
    const theModel: ChannelLinkModel = new ChannelLinkModel();

    if (!isNullOrUndefined(inFireNode.key)) {
      // Store the key to the firebaseData
      theModel._key = inFireNode.key;

      // Retirieve the payload of the firebase data
      const thePayload = inFireNode.payload.val();

      if (!isNullOrUndefined(thePayload.articleKey)) {
        theModel._articleKey = thePayload.articleKey;
      }

      if (!isNullOrUndefined(thePayload.channelDefKey)) {
        theModel._channelDefKey = thePayload.channelDefKey;
      }
    }
    return theModel;
  }

  /**
   * ChannelLinkModel()
   *
   * @constructor
   */
  public ChannelModel() {

  }


  /**
   * toFirebase()
   *
   * Returns a fireBase node with data from the object
   *
   * @returns {any}
   */
  public toFirebase(): any {
    const theObj: any = {
      articleKey: this._articleKey,
      channelDefKey: this._channelDefKey
    };

    return theObj;
  }


  /**
   * hasKey()
   *
   * Returns true if the model has a key (thus comes from the fireBase databae,
   * and false when no key is present (thus never been saved).
   *
   * @returns {boolean}
   */
  public hasKey(): boolean {
    return !isNullOrUndefined(this._key) && this._key.length > 0;
  }

  /*

  GETTERS AND SETTERS

   */


  get key(): string {
    return this._key;
  }

  set key(value: string) {
    this._key = value;
  }

  get articleKey(): string {
    return this._articleKey;
  }

  set articleKey(value: string) {
    this._articleKey = value;
  }

  get channelDefKey(): string {
    return this._channelDefKey;
  }

  set channelDefKey(value: string) {
    this._channelDefKey = value;
  }

  get channelName(): string {
    return this._channelName;
  }

  set channelName(value: string) {
    this._channelName = value;
  }
}
