import { Component } from '@angular/core';
import { environment } from '../environments/environment';
import { ArticleService } from './services/article.service';
import { ArticleModel } from './models/article-model';
import { SettingsService } from './services/settings.service';
import { DomainDefModel } from './models/settings/domain-def-model';
import { ChannelDefModel } from './models/settings/channel-def-model';
import { ApiKeysService } from './services/api-keys.service';
import { ApiKeyModel } from './models/api-key-model';
import { AuthService } from './services/auth.service';
import * as firebase from 'firebase/app';
import { RolesService } from './services/roles.service';
import { RoleModel } from './models/role-model';
import { CloudTriggerService } from './services/cloud-trigger.service';
import { CloudTriggerModel } from './models/cloud-trigger-model';
import { first } from '../../node_modules/rxjs/operators';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  environmentName = environment.name;

  /**
   * constructor()
   *
   * @param {ArticleService} articleService
   * @param {SettingsService} settingsService
   * @param {ApiKeysService} apiKeyService
   * @param {AuthService} authService
   * @param {RolesService} rolesService
   * @param {CloudTriggerService} cloudTriggerService
   */
  constructor(
    private articleService: ArticleService,
    private settingsService: SettingsService,
    private apiKeyService: ApiKeysService,
    private authService: AuthService,
    private rolesService: RolesService,
    private cloudTriggerService: CloudTriggerService
  ) {

    authService.getLoggedInUser().subscribe((inUser: firebase.User) => {
      // Determin the role of the user
      this.rolesService.determineRoles(inUser.email, inUser.uid, inUser.displayName, inUser.photoURL);

      // Check for init status
      this.rolesService.getSysAdminList().subscribe((inList: Array<RoleModel>) => {
        if (inList.length === 0) {

          // No sys admins define. Create default using cloud service
          const theCloudTrigger: CloudTriggerModel = new CloudTriggerModel();
          theCloudTrigger.action = CloudTriggerService.sysadmininit;
          this.cloudTriggerService.addTrigger(theCloudTrigger);
        }
      });
    });

 

  }


  /**
   * userOpenedArticle()
   *
   * Called when u user wants to open or edit an aricle.
   *
   * @param {ArticleModel} inArticle      The article to be opened or edited
   * @param inDoEdit                      if true: user wants to edit, if false: user wants to open
   */
  private userOpenedArticle(inArticle: ArticleModel, inDoEdit) {
    if (inDoEdit) {
   //   console.log('Edit: ' + inArticle.title);
    } else {
   //   console.log('Open: ' + inArticle.title);
   
    }

  }
}
