import { Component, OnInit, OnDestroy } from '@angular/core';
import { AllowedUserModel } from '../../../../models/allowed-user-model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SettingsService } from '../../../../services/settings.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-allowed-user-editor',
  templateUrl: './allowed-user-editor.component.html',
  styleUrls: ['./allowed-user-editor.component.css']
})
export class AllowedUserEditorComponent implements OnInit, OnDestroy {

  public allowedUser: AllowedUserModel = new AllowedUserModel();
  routeParamsSubscription$: any;


  constructor(private snackbar: MatSnackBar,
    private settingsService: SettingsService, private router: Router, private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.routeParamsSubscription$ = this.route.params.subscribe(params => {
      if (params['key'] !== '') {
        this.settingsService.getAllowedUserByKey(params['key']).subscribe(result => {
          this.allowedUser = result;
        });
      }

    });

  }

  ngOnDestroy(): void {
    this.routeParamsSubscription$.unsubscribe();
  }

  public addUser() {
    this.settingsService.addAllowedUser(this.allowedUser);
    this.router.navigate(['admin', { tab: 'settings' }]);
    this.openSnackbar('User ' + this.allowedUser.email + ' added to allowed list', '');
  }

  public deleteUser() {
    this.settingsService.deleteAllowedUser(this.allowedUser.key);
    this.router.navigate(['admin', { tab: 'settings' }]);
  }

  public cancel() {
    this.router.navigate(['admin', { tab: 'settings' }]);
  }

  openSnackbar(message: string, action: string) {
    this.snackbar.open(message, action, {
      duration: 2000,
    });
  }




}
