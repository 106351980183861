/**
 * ChannelDefModel
 *
 * Models the definition of a single publication Channel
 *
 * Articles can be published on one or more publication channels.
 * Publication channel definitions hold all definitions for such a channel
 * The ChannelDefModels are stored as part of the SettingsModel
 */

import {isNullOrUndefined} from 'util';


export class ChannelDefModel {
  private _key: string = '';
  private _name: string = '';
  private _label: string = '';

  /**
   * fromFirebase)
   *
   * Returns an ChannelDefModel constructed from fireBase data
   *
   * @param inFireNode
   * @returns {ChannelDefModel}
   */
  public static fromFirebase(inFireNode: any): ChannelDefModel {
    const theModel: ChannelDefModel = new ChannelDefModel();

    if (!isNullOrUndefined(inFireNode.key)) {
      // Store the key to the firebaseData
      theModel._key = inFireNode.key;

      // Retirieve the payload of the firebase data
      const thePayload = inFireNode.payload.val();

      if (!isNullOrUndefined(thePayload.name)) {
        theModel._name = thePayload.name;
      }

      if (!isNullOrUndefined(thePayload.label)) {
        theModel._label = thePayload.label;
      }
    }

    return theModel;
  }

  /**
   * ChannelDefModel()
   *
   * @constructor
   */
  public ChannelDefModel() {

  }

  /**
   * toFirebase()
   *
   * Returns a fireBase node with data from the object
   *
   * @returns {any}
   */
  public toFirebase(): any {
    const theObj: any = {
      name: this._name,
      label: this._label
    };

    return theObj;
  }

  /**
   * hasKey()
   *
   * Returns true if the model has a key (thus comes from the fireBase databae,
   * and false when no key is present (thus never been saved).
   *
   * @returns {boolean}
   */
  public hasKey(): boolean {
    return !isNullOrUndefined(this._key) && this._key.length > 0;
  }

  /*

  GETTERS AND SETTERS

   */


  get key(): string {
    return this._key;
  }

  set key(value: string) {
    this._key = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get label(): string {
    return this._label;
  }

  set label(value: string) {
    this._label = value;
  }
}
