import { Component, OnInit } from '@angular/core';
import {EditorModel} from '../../../models/editor-model';
import {RolesService} from '../../../services/roles.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-editor-list',
  templateUrl: './editor-list.component.html',
  styleUrls: ['./editor-list.component.css']
})
export class EditorListComponent implements OnInit {
  public editors: Array<EditorModel> = [];

  constructor(
    private rolesService: RolesService,
    private router: Router
  ) {
    this.rolesService.getEditorsList().subscribe((editorList: Array<EditorModel>) => {
      this.editors = editorList;
    });
  }

  ngOnInit() {
  }

  public editEditor(inEditor: EditorModel) {
    this.router.navigate(['admin/edit', inEditor.key]);
  }

  public newEditor() {
    this.router.navigate(['admin/edit', '']);
  }
}
