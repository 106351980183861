/**
 * ChannelDefPipe
 *
 * Returns the channelDef name for a given channelDef key
 */
import { Pipe, PipeTransform } from '@angular/core';
import {ChannelDefModel} from '../models/settings/channel-def-model';

@Pipe({
  name: 'channelDef'
})
export class ChannelDefPipe implements PipeTransform {

  transform(inChannelDefKey: string, inChannelDefs?: Array<ChannelDefModel>): any {
    let theChannelDefName: string = inChannelDefKey;

    inChannelDefs.forEach((inChannelDef: ChannelDefModel) => {
      if (inChannelDef.key === inChannelDefKey) {
        theChannelDefName = inChannelDef.name;
      }
    });
    return theChannelDefName;
  }

}
