import { Injectable } from '@angular/core';
import {Subscription} from 'rxjs';
import {CloudTriggerModel} from '../models/cloud-trigger-model';
import {AngularFireDatabase, AngularFireList} from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class CloudTriggerService {
  // Defines actions
  static readonly sysadmininit = 'sysadmininit';                // Cloud function that will initialize the sysadmins


  // node name definitions
  static readonly cloudTriggersNodeName = 'cloudTriggers';      // Node name of the Cloud Triggers node in fireBase

  private _triggers$: Subscription;
  private _triggersRef: AngularFireList<any>;

  constructor(private db: AngularFireDatabase) {
    this._triggersRef = db.list(CloudTriggerService.cloudTriggersNodeName);
  }

  public addTrigger(inTrigger: CloudTriggerModel) {
    // Create a new node
    this._triggersRef.push(inTrigger.toFirebase());
  }
}
