/**
 * ContentModel
 *
 * The content is stored seperate from the Article. This is done to reduce the size of the Article models
 * so they can be used in list. The content model stores only the content of an article.
 */
import {isNullOrUndefined} from 'util';

export class ContentModel {
  private _key: string = '';            // fireBase key for this node
  private _content: string = '';        // The content of the article (an html string)
  private _viewCount: number = 0;       // Number of times the article is viewed

  /**
   * fromFirebase()
   *
   * Returns an ArticleModel constructed from fireBase data
   *
   * @param inFireNode          any   data as received from fireBase
   * @returns {ContentModel}
   */
  public static fromFirebase(inFireNode: any): ContentModel {
    // Create a new empty model
    const theModel: ContentModel = new ContentModel();

    if (!isNullOrUndefined(inFireNode.key)) {
      // Store the key to the firebaseData
      theModel._key = inFireNode.key;

      // Retirieve the payload of the firebase data
      const thePayload = inFireNode.payload.val();

      // Decode the node elements
      if (!isNullOrUndefined(thePayload.content)) {
        theModel._content = thePayload.content;
      }
      if (!isNullOrUndefined(thePayload.viewCount)) {
        theModel._viewCount = thePayload.viewCount;
      }
    }

    return theModel;
  }

  /**
   * ContentModel()
   *
   * @constructor
   */
  public ContentModel() {

  }

  /**
   * toFirebase()
   *
   * Returns a fireBase node with data from the object
   *
   * NOTE: The element viewCount is NOT saved here!!! This is intentional
   * The ViewCount should be set only when the article is viewed.
   *
   * @returns {any}
   */
  public toFirebase(): any {
    return {
      content:     this._content
    };
  }

  /**
   * hasKey()
   *
   * Returns true if the model has a key (thus comes from the fireBase databae,
   * and false when no key is present (thus never been saved).
   *
   * @returns {boolean}
   */
  public hasKey(): boolean {
    return !isNullOrUndefined(this._key) && this._key.length > 0;
  }

  /*

  GETTERS AND SETTERS

 */

  get key(): string {
    return this._key;
  }

  set key(value: string) {
    this._key = value;
  }

  get content(): string {
    return this._content;
  }

  set content(value: string) {
    this._content = value;
  }


  get viewCount(): number {
    return this._viewCount;
  }

  set viewCount(value: number) {
    this._viewCount = value;
  }
}
