import { Component, OnInit } from '@angular/core';
import {RolesService} from '../../services/roles.service';
import {EditorModel} from '../../models/editor-model';

@Component({
  selector: 'app-admin-view',
  templateUrl: './admin-view.component.html',
  styleUrls: ['./admin-view.component.css']
})
export class AdminViewComponent implements OnInit {

  constructor() {}

  ngOnInit() {
  }

}
