/**
 * SettingsService
 *
 * The settingService provides all database actions for the app settings
 *
 * The is designed to be highly configurable. To achieve this, configurable
 * settings need to be stored in the database.
 *
 * channelDefs:
 * Articles can be published to one or more channels. The definition of these
 * channels are contained in the settings/chasnnelDefs.
 *
 * supportedDomains:
 * Articles are private for organisations. Users should come from a supported domain
 * (checked through their verified email address). These supported domains
 * are contained in the settings/supportedDomains.
 */

import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { ChannelDefModel } from '../models/settings/channel-def-model';
import { map } from 'rxjs/operators';
import { DomainDefModel } from '../models/settings/domain-def-model';
import { RolesService } from './roles.service';
import { EditorModel } from '../models/editor-model';
import { AllowedUserModel } from '../models/allowed-user-model';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  // node name definitions
  static readonly settingsNodeName = 'settings';
  static readonly channelDefsNodeName = 'channelDefs';
  static readonly supportedDomainsNodeName = 'supportedDomains';
  static readonly invitesNodeName = 'allowedUsers';
  
  // Member definitions
  private _channelDefsRef: AngularFireList<any>;
  private __supportedDomainsRef: AngularFireList<any>;
  private allowedUsersRef: AngularFireList<string>;

  /**
   * constructor
   *
   * @param {AngularFireDatabase} db
   */
  constructor(private db: AngularFireDatabase, private afStorage: AngularFireStorage) {
    // Create a reference for the channel definitions
    this._channelDefsRef = db.list(SettingsService.settingsNodeName + '/' + SettingsService.channelDefsNodeName);

    // Create a reference for the supported Domains
    this.__supportedDomainsRef = db.list(SettingsService.settingsNodeName + '/' + SettingsService.supportedDomainsNodeName);

    // Create a reference for the allowed users
    this.allowedUsersRef = db.list(SettingsService.settingsNodeName + '/' + SettingsService.invitesNodeName);
  }

  /**
   * retrieveChannelDefs()
   *
   * Returns an Observable to the array with ChannelDefModels
   *
   * @returns {Observable<Array<ChannelDefModel>>}
   */
  public retrieveChannelDefs(): Observable<ChannelDefModel[]> {
    return this._channelDefsRef.snapshotChanges().pipe(
      map((changes) =>
        changes.map(channelDefNode => (
          ChannelDefModel.fromFirebase(channelDefNode))
        ).sort((a, b) => {
          if (a.label.toLowerCase() < b.label.toLowerCase()) { return -1; }
          if (a.label.toLowerCase() > b.label.toLowerCase()) { return 1; }
            return 0;
        })
      )
    );
  }



  public retrieveChannelDef(inKey: string): Promise<ChannelDefModel> {
    // Create a new promise
    const thePromise = new Promise<ChannelDefModel>((resolve, reject) => {

      if (inKey && inKey.length > 0) {

        // Retrieve the data
        const theSubscription = this.db.object(SettingsService.settingsNodeName + '/' + SettingsService.channelDefsNodeName + '/' + inKey).snapshotChanges().subscribe(
          inData => {

            // Parse the data
            const theChannel: ChannelDefModel = ChannelDefModel.fromFirebase(inData);

            // Make sure to unsubscribe
            theSubscription.unsubscribe();

            // And resolve the promise
            resolve(theChannel);
          });

      } else {
        // Empty key received, return a new channel
        const theChannel: ChannelDefModel = new ChannelDefModel();
        resolve(theChannel);
      }
    });


    return thePromise;
  }

  /**
   * storeChannelDef()
   *
   * Stores a channelDef in the fireBase database. Will update the existing
   * channelDef if a key is present, will create a new channelDef if the key is empty
   *
   * @param {ChannelDefModel} inChannelDef
   */
  public storeChannelDef(inChannelDef: ChannelDefModel) {

    // Is it an existing defintions or a new one
    if (inChannelDef.hasKey()) {

      // Update the existing node
      this._channelDefsRef.update(inChannelDef.key, inChannelDef.toFirebase());
    } else {

      // Create a new node
      this._channelDefsRef.push(inChannelDef.toFirebase());
    }
  }

  /**
   * removeChannelDef()
   *
   * Removes a channelDef from the database
   *
   * @param {ChannelDefModel} inChannelDef
   */
  public removeChannelDef(inChannelDef: ChannelDefModel) {
    if (inChannelDef.hasKey()) {
      this._channelDefsRef.remove(inChannelDef.key);
    }
  }

  /**
   * retrieveSupportedDomains()
   *
   * Returns an Observable to the array with DomainDefModel
   *
   * @returns {Observable<Array<DomainDefModel>>}
   */
  public retrieveSupportedDomains(): Observable<Array<DomainDefModel>> {
    return this.__supportedDomainsRef.snapshotChanges().pipe(
      map(changes =>
        changes.map(domainNode => (DomainDefModel.fromFirebase(domainNode))))
    );
  }

  /**
   * retrieveSupportedDomain()
   *
   *
   *
   * @param {string} inKey
   * @returns {Promise<DomainDefModel>}
   */
  public retrieveSupportedDomain(inKey: string): Promise<DomainDefModel> {
    const thePromise: Promise<DomainDefModel> = new Promise<DomainDefModel>((resolve, reject) => {
      if (inKey && inKey.length > 0) {

        // Retrieve the data
        const theSubscription = this.db.object(SettingsService.settingsNodeName + '/' + SettingsService.supportedDomainsNodeName + '/' + inKey).snapshotChanges().subscribe(
          inData => {
            // Parse the data
            const theDomain: DomainDefModel = DomainDefModel.fromFirebase(inData);

            // Make sure to unsubscribe
            theSubscription.unsubscribe();

            // And resolve the promise
            resolve(theDomain);
          });
      } else {
        const theDomain: DomainDefModel = new DomainDefModel();
        resolve(theDomain);
      }
    });

    return thePromise;
  }

  /**
   * storeDomain()
   *
   * Stores a channelDef in the fireBase database. Will update the existing
   * channelDef if a key is present, will create a new channelDef if the key is empty
   *
   * @param {DomainDefModel} inDomainDef
   */
  public storeDomain(inDomainDef: DomainDefModel) {

    // Is it an existing defintions or a new one
    if (inDomainDef.hasKey()) {

      // Update the existing node
      this.__supportedDomainsRef.update(inDomainDef.key, inDomainDef.toFirebase());
    } else {

      // Create a new node
      this.__supportedDomainsRef.push(inDomainDef.toFirebase());
    }
  }

  /**
   * removeDomain()
   *
   * Removes a domainDef from the database
   *
   * @param {DomainDefModel} inDomainDef
   */
  public removeDomain(inDomainDef: DomainDefModel) {
    if (inDomainDef.hasKey()) {
      this.__supportedDomainsRef.remove(inDomainDef.key);
    }
  }



  public addAllowedUser(inObj: AllowedUserModel) {
    if (inObj.key !== '' && inObj.key !== null) {
      this.allowedUsersRef.update(inObj.key, inObj.toFirebase());
    } else {
      this.allowedUsersRef.push(inObj.toFirebase());
    }
  }

  public getAllowedUsers(): Observable<any> {
    return this.allowedUsersRef.snapshotChanges().pipe(
      map(allowedUsersList => {
        return allowedUsersList.map(userObj => {
           return AllowedUserModel.fromFirebase(userObj);
        });
      })
    );
  }

  public getAllowedUserByKey(inKey: string) {
    return this.db.object('settings/allowedUsers/' + inKey).snapshotChanges().pipe(
      map(result => {
        return AllowedUserModel.fromFirebase(result);
      })
    );

  }

  public deleteAllowedUser(inKey: string) {
    if (inKey) {
      this.allowedUsersRef.remove(inKey);
    }
  }

  public getLogo() {
    return this.afStorage.ref('images/logo/logo.png').getDownloadURL();
  }

}
