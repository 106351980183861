import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ArticleModel } from '../../models/article-model';
import { ArticleService } from '../../services/article.service';
import { RolesService } from '../../services/roles.service';
import { Subscription, Observable } from 'rxjs';
import { ChannelDefModel } from 'src/app/models/settings/channel-def-model';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-viewarticle',
  templateUrl: './viewarticle.component.html',
  styleUrls: ['./viewarticle.component.css']
})
export class ViewarticleComponent implements OnInit, OnDestroy {

  public article: ArticleModel = new ArticleModel();
  private subscription: Subscription;
  public isEditor = false;
  public mayEditByChannel;
  public channelDefs$: Observable<ChannelDefModel[]>;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private articleService: ArticleService,
    public rolesService: RolesService,
    private settingsService: SettingsService) {
    this.isEditor = rolesService.isEditor;
  }

  ngOnInit() {

    // Gets the key from the route parameters 
    this.subscription = this.route.params.subscribe(params => {
      this.articleService.retrieveArticle(params['articleKey']).then((result: ArticleModel) => {
        this.article = result;
        this.mayEditByChannel = this.rolesService.mayEditByChannel(this.article);

        // Update the view counter
        this.articleService.incViewCount(this.article.contentKey);
      });
    });

    this.channelDefs$ = this.settingsService.retrieveChannelDefs();

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  editArticle() {
    this.router.navigate(['articles/edit', this.article.key]);
  }

  toArticleList() {
    this.router.navigate(['articles']);

  }


}
