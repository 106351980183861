import {isNullOrUndefined} from 'util';

export class AllowedUserModel {
  private _key: string = '';
  private _email: string = '';

  /**
   * fromFirebase()
   *
   * Returns an SitesModel constructed from fireBase data
   *
   * @param inFireNode          any   data as received from fireBase
   * @returns {SitesModel}
   */
  public static fromFirebase(inFireNode: any): AllowedUserModel {
    // Create a new empty model
    const theModel: AllowedUserModel = new AllowedUserModel();

    if (!isNullOrUndefined(inFireNode.key)) {
      // Store the key to the firebaseData
      theModel._key = inFireNode.key;

      // Retirieve the payload of the firebase data
      const thePayload = inFireNode.payload.val();

      // Decode the node elements
      if (!isNullOrUndefined(thePayload.email)) {
        theModel._email = thePayload.email;
      }
    }

    return theModel;
  }


  /**
   * toFirebase()
   *
   * Returns a fireBase node with data from the object
   *
   */
  public toFirebase(): any {
    return {
      email:              this._email
    };
  }

  /**
   * hasKey()
   *
   * Returns true if the model has a key (thus comes from the fireBase databae,
   * and false when no key is present (thus never been saved).
   *
   * @returns {boolean}
   */
  public hasKey(): boolean {
    return !isNullOrUndefined(this._key) && this._key.length > 0;
  }

  /*
  Getters and setters
   */

  get key(): string {
    return this._key;
  }

  set key(value: string) {
    this._key = value;
  }

  /**
   * Getter title
   * @return {string }
   */
  public get email(): string {
    return this._email;
  }

  /**
   * Setter title
   * @param {string } value
   */
  public set email(value: string) {
    this._email = value;
  }


}
