import { Component, OnInit } from '@angular/core';
import {ApiKeyModel} from '../../../models/api-key-model';
import {Router} from '@angular/router';
import {RolesService} from '../../../services/roles.service';
import {RoleModel} from '../../../models/role-model';
import {ApiKeysService} from '../../../services/api-keys.service';

@Component({
  selector: 'app-apikey-list',
  templateUrl: './apikey-list.component.html',
  styleUrls: ['./apikey-list.component.css']
})
export class ApikeyListComponent implements OnInit {
  public apikeys: Array<ApiKeyModel> = [];

  constructor(
    private rolesService: RolesService,
    private router: Router,
    private apikeyService: ApiKeysService
  ) {
      this.apikeyService.retrieveApiKeys().subscribe((inList: Array<ApiKeyModel>) => {
      this.apikeys = inList;
    });
  }

  ngOnInit() {
  }

  public editApikey(inApikey: ApiKeyModel) {
    this.router.navigate(['sysadmin/editapikey', inApikey.key]);
  }

  public newApikey() {
    this.router.navigate(['sysadmin/editapikey', '']);
  }
}
