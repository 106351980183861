/**
 * ArticleService
 *
 * A service that allows you to subscribe to the list with articles,
 * retrieve a complete article (including the content), store a complete article
 * and delete a complete article.
 */

import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { ArticleModel } from '../models/article-model';
import { ContentModel } from '../models/content-model';
import { Observable, combineLatest } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { ChannelDefModel } from '../models/settings/channel-def-model';
import { ChannelLinkModel } from '../models/channel-link-model';


@Injectable({
  providedIn: 'root'
})
export class ArticleService {
  // node name definitions
  static readonly articleNodeName = '/articles';
  static readonly contentNodeName = '/content';
  static readonly channelLinksNodeName = '/channelLinks';

  // Member definitions
  private _articlesRef: AngularFireList<any>;
  private _contentRef: AngularFireList<any>;
  private _channelLinksRef: AngularFireList<any>;

  /**
   * ArticleService()
   *
   * @param {AngularFireDatabase} db
   */
  constructor(
    private db: AngularFireDatabase
  ) {
    this._articlesRef = db.list(ArticleService.articleNodeName);
    this._contentRef = db.list(ArticleService.contentNodeName);
    this._channelLinksRef = db.list(ArticleService.channelLinksNodeName);
  }


  /**
   * retrieveArticleList()
   *
   *
   * @returns {Observable<Array<ArticleModel>>}
   */
  public retrieveArticleList(): Observable<Array<ArticleModel>> {

    return this._articlesRef.snapshotChanges().pipe(
      map(changes =>
        changes.map(theArticleNode =>
          (ArticleModel.fromFirebase(theArticleNode))
        )));
  }

  filterOnChannels(inChannelKeys: string[]): Observable<ArticleModel[]> {
    if (inChannelKeys.length > 0) {
      return this.retrieveArticleList().pipe(
        map(articles => {
          const theArticles: ArticleModel[] = [];
          articles.forEach(article => {

            article.channels.forEach(articleChannel => {

              if (inChannelKeys.some(channel => channel === articleChannel)) {
                if (theArticles.indexOf(article) < 0) {
                  theArticles.push(article);

                }
              }
            })



          })
          return theArticles;
        })
      )
    }
    return this.getArticlesFromIndex(null);
  }

  queryArticles(inQuery): Observable<ArticleModel[]> {

    const articles$ = this.retrieveArticleList();
    const allContent$ = this.retrieveAllContent();

   return combineLatest(articles$, allContent$, (articles, allContent) => {

      const filteredArticles = articles.filter(
        article => this.filterArticlesOnTitle(inQuery, article) || this.filterArticlesOnSummary(inQuery, article) && article.published);

      const filteredContent = allContent.filter(theContent => this.filterOnContent(inQuery, theContent));


      articles.forEach(article => {
        if (filteredContent.some(content => content.key === article.contentKey)) {
          if (filteredArticles.indexOf(article) < 0) {
            filteredArticles.push(article);

          }
        }
      })
      return filteredArticles;
    })

    // return this.retrieveArticleList().pipe(
    //   map(articles => {
    //     const content: string[] = [];
    //     articles.forEach(article => {

    //     })
    //     const filteredArticles = articles.filter(
    //       article => this.filterArticlesOnTitle(inQuery, article) || this.filterArticlesOnSummary(inQuery, article));
    //     return null;
    //     //TODO: sort articles

    //   })
    // )
  }

  filterOnContent(inQuery, inContent: ContentModel) {
    return inContent.content.toLowerCase().includes(inQuery.toLowerCase());
  }

  filterArticlesOnTitle(inQuery: string, inArticle: ArticleModel) {
    return inArticle.title.toLowerCase().includes(inQuery.toLowerCase());
  }

  filterArticlesOnSummary(inQuery: string, inArticle: ArticleModel) {
    return inArticle.summary.toLowerCase().includes(inQuery.toLowerCase());

  }


  getArticlesFromIndex(inArticle: ArticleModel): Observable<ArticleModel[]> {

    if (inArticle) {
      // return this.db.list('articles', ref=> ref.orderByChild('lastUpdateInMs', ).startAt(inArticle.key)).snapshotChanges().pipe(
      return this.db.list('articles', ref => ref.orderByKey().endAt(inArticle.key).limitToLast(11)).snapshotChanges().pipe(
        map(changes =>
          changes.map(theArticleNode =>
            (ArticleModel.fromFirebase(theArticleNode))
          ).sort(this.sortArticles)));
    }

    //inStartArticle
    return this.db.list('articles', ref => ref.limitToLast(15)).snapshotChanges().pipe(
      map(changes =>
        changes.map(theArticleNode =>
          (ArticleModel.fromFirebase(theArticleNode))
        ).sort(this.sortArticles)));
  }

  searchArticle(inQuery): Observable<ArticleModel[]> {
    return this.db.list('articles', ref => ref.orderByChild('title').startAt(inQuery).endAt(inQuery + "\uf8ff").limitToLast(10)).snapshotChanges().pipe(
      map(changes =>
        changes.map(theArticleNode =>
          (ArticleModel.fromFirebase(theArticleNode))
        )));

  }

  public sortArticles(a: ArticleModel, b: ArticleModel) {

    if (a.lastUpdateDateInMs < b.lastUpdateDateInMs) {
      return -1;
    }
    if (a.lastUpdateDateInMs > b.lastUpdateDateInMs) {
      return 1
    }

    return 0;
    // return dateA.getTime() - dateB.getTime();
  }
  /**
   * retrieveArticle()
   *
   * Returns an article with inKey from the database
   *
   * @param {string} inKey
   * @returns {ArticleModel}
   */
  public retrieveArticle(inKey: string): Promise<ArticleModel> {

    // Create a new promise
    return new Promise<ArticleModel>((resolve) => {

      if (inKey && inKey.length > 0) {

        // Retrieve the data
        const theSubscription = this.db.object(ArticleService.articleNodeName + '/' + inKey).snapshotChanges().subscribe(
          inData => {

            // Parse the data
            const theArticle = ArticleModel.fromFirebase(inData);

            // Make sure to unsubscribe
            theSubscription.unsubscribe();

            // Collect the content if available
            if (theArticle.contentKey.length > 0) {
              this._retrieveContent(theArticle.contentKey).then(theContent => {
                // Copy the content
                theArticle.content = theContent.content;
                theArticle.viewCount = theContent.viewCount;

                // And resolve the promise
                resolve(theArticle);
              });
            } else {
              // And resolve the promise
              resolve(theArticle);
            }
          });
      } else {

        // Empty key received, return a new article
        const theArticle = new ArticleModel();
        resolve(theArticle);
      }


    });
  }

  retrieveArticleObservable(inKey: string) {
    return this.db.object(ArticleService.articleNodeName + '/' + inKey).snapshotChanges().pipe(
      map(result => {
        return ArticleModel.fromFirebase(result);
      })
      // TODO: retreive articlecontent 
    );

  }


  /**
   * storeArticle()
   *
   * Stores an article in the fireBase database. Will update the existing
   * article if a key is present, will create a new article if the key is empty
   *
   * @param {ArticleModel} inArticle                    The article to be saved
   * @param {Array<ChannelDefModel>} inChannelDefs      The list of all known channels
   * @param {string} inEditorEmail                      The email address of the user/editor who is storing the article
   */
  public storeArticle(inArticle: ArticleModel, inChannelDefs: Array<ChannelDefModel>, inEditorEmail: string) {

    // Check if a key is present.
    if (inArticle.hasKey()) {

      // A key is present, so update the existing article

      // Store the content
      this._storeContent(inArticle.contentKey, inArticle.content);

      // Update the existing article
      inArticle.lastUpdateDateInMs = new Date().getTime();
      inArticle.lastUpdatedBy = inEditorEmail;

      this._articlesRef.update(inArticle.key, inArticle.toFirebase());

      // Update the channel links
      this._updateChannelLinks(inArticle, inChannelDefs);

    } else {

      // No key is present, so this is a new article. Push it to fireBase

      // Store the content
      this._storeContent(inArticle.contentKey, inArticle.content).then(theContent => {

        // Add the contentKey to the article
        inArticle.contentKey = theContent.key;

        // Store the article
        inArticle.lastUpdateDateInMs = new Date().getTime();
        inArticle.lastUpdatedBy = inEditorEmail;
        inArticle.creationDateInMs = new Date().getTime();
        inArticle.createdBy = inEditorEmail;

        this._articlesRef.push(inArticle.toFirebase()).then((theArticle) => {

          // Add the generated key to the model.
          inArticle.key = theArticle.key;

          // Update the channel links
          this._updateChannelLinks(inArticle, inChannelDefs);
        });
      });

    }
  }

  /**
   * removeArticle()
   *
   * Removes the article inArticle and the connected content
   *
   * @param {ArticleModel} inArticle
   */
  public removeArticle(inArticle: ArticleModel) {

    // Check if a key is present.
    if (inArticle.hasKey()) {

      if (inArticle.contentKey.length > 0) {
        this._removeContent(inArticle.contentKey);
      }

      this._articlesRef.remove(inArticle.key).then();
    }
  }

  retrieveAllContent(): Observable<ContentModel[]> {
    return this.db.list(ArticleService.contentNodeName).snapshotChanges().pipe(
      map(results => {
        return results.map(result => {
          return ContentModel.fromFirebase(result);
        })
      })
    )
  }


  /**
   * _retrieveContent()
   *
   * Returns a content with inKey from the database
   *
   * @param {string} inKey
   * @returns {Promise<ContentModel>}
   * @private
   */
  private _retrieveContent(inKey: string): Promise<ContentModel> {

    // Create a new promise
    // return new Promise<ContentModel>((resolve) => {
    // Retrieve the data

    return this.db.object(ArticleService.contentNodeName + '/' + inKey).snapshotChanges().pipe(
      map(result => {
        return ContentModel.fromFirebase(result);
      }),
      first()
    ).toPromise();
    //   const theSubscription = this.db.object(ArticleService.contentNodeName + '/' + inKey).snapshotChanges().subscribe(
    //     inData => {
    //       const theContent = ContentModel.fromFirebase(inData);

    //       // Make sure to unsubscribe
    //       theSubscription.unsubscribe();

    //       resolve(theContent);
    //     });
    // });
  }

  // private _retrieveContent$(inKey: string): Observable<ContentModel> {

  // }

  /**
   * _storeContent()
   *
   * Updates the content of the article, creating a new content node of the content.key
   * is empty, updates the existing content node if the key is present.
   *
   * @param {string} inContentKey     string      The current key for the content
   * @param {string} inContent        string      The content itself
   *
   * @returns {Promise<ContentModel>}   Returns the complete content, including generated key
   * @private
   */
  private _storeContent(inContentKey: string, inContent: string): Promise<ContentModel> {
    const theContent: ContentModel = new ContentModel();
    theContent.key = inContentKey;
    theContent.content = inContent;

    if (theContent.hasKey()) {
      // Create the promise
      return new Promise<ContentModel>((resolve) => {

        // Update the content
        this._contentRef.update(theContent.key, theContent.toFirebase()).then(() => {
          resolve(theContent);
        });
      });

    } else {
      // Create the promise
      return new Promise<ContentModel>((resolve) => {

        // create new content
        this._contentRef.push(theContent.toFirebase()).then(theStoredContent => {
          theContent.key = theStoredContent.key;

          resolve(theContent);
        });
      });
    }
  }

  /**
   * incViewCount()
   *
   * Increments the viewcount for the article
   * Should be called whenever someone views (not edits) the article
   *
   * @param inContentKey
   */
  public incViewCount(inContentKey: string) {
    this.db.object(ArticleService.contentNodeName + `/${inContentKey}/viewCount`)
      .query.ref.transaction(viewCount => {
        if (viewCount === null) {
          return viewCount = 1;
        } else {
          return viewCount + 1;
        }
      }).then();
  }

  /**
   * _removeContent()
   *
   * Removes the content node with key inContentKey
   *
   * @param {string} inContentKey
   * @private
   */
  private _removeContent(inContentKey: string) {
    if (inContentKey.length > 0) {
      this._contentRef.remove(inContentKey);
    }
  }

  /**
   * _updateChannelLinks()
   *
   * This updates the channelLink list from the current state of the Article
   * This should be called whenever an Article is stored.
   *
   * If the article is NOT published, remove any existing links
   *
   * @param {ArticleModel} inArticle
   * @param {Array<ChannelDefModel>} inChannelDefs
   * @private
   */
  private _updateChannelLinks(inArticle: ArticleModel, inChannelDefs: Array<ChannelDefModel>) {

    // collect all channelLinks for this article
    // Create the query
    const theChannelLinksRef = this.db.list(
      ArticleService.channelLinksNodeName, ref => ref.orderByChild('articleKey').equalTo(inArticle.key));

    // Collect the data
    const theChannelLink$ = theChannelLinksRef.snapshotChanges().pipe(
      map(changes =>
        changes.map(theChannelLinkNode =>
          (ChannelLinkModel.fromFirebase(theChannelLinkNode))
        )));
    const theLinks$ = theChannelLink$.subscribe((inChannelLinks: Array<ChannelLinkModel>) => {

      if (inArticle.published) {

        // Remove any channelDefs that are (no longer) present
        inChannelLinks.forEach((inChannelLink: ChannelLinkModel) => {

          // Is this link still present (is the channelDef.key present in the articleModel.channels
          if (inArticle.channels.indexOf(inChannelLink.channelDefKey) < 0) {

            // Remove it
            this._channelLinksRef.remove(inChannelLink.key);
          }
        });

        // Add and channelDefs that are not yet present
        inArticle.channels.forEach((inChannelDefKey: string) => {

          let isLinkPresent = false;

          // Try to find the link
          inChannelLinks.forEach((inChannelLink: ChannelLinkModel) => {
            if (inChannelLink.channelDefKey === inChannelDefKey) {
              isLinkPresent = true;
            }
          });

          // If this channelDefKey is NOT present, add it
          if (!isLinkPresent) {
            const theNewLink: ChannelLinkModel = new ChannelLinkModel();
            theNewLink.channelDefKey = inChannelDefKey;
            theNewLink.articleKey = inArticle.key;

            this._channelLinksRef.push(theNewLink.toFirebase());
          }
        });
      } else {
        // Remove all channel links

        inChannelLinks.forEach((inChannelLink: ChannelLinkModel) => {

          // Remove it
          this._channelLinksRef.remove(inChannelLink.key);
        });
      }



      theLinks$.unsubscribe();
    });
  }
}
