import { Component } from '@angular/core';
import {Router} from '@angular/router';
import {SettingsService} from '../../../services/settings.service';
import {DomainDefModel} from '../../../models/settings/domain-def-model';
import {RoleModel} from '../../../models/role-model';

@Component({
  selector: 'app-domain-list',
  templateUrl: './domain-list.component.html',
  styleUrls: ['./domain-list.component.css']
})
export class DomainListComponent {
  public domains: Array<DomainDefModel> = [];

  constructor(
    private settingsService: SettingsService,
    private router: Router
  ) {
    this.settingsService.retrieveSupportedDomains().subscribe((inList: Array<DomainDefModel>) => {
      this.domains = inList;
    });
  }

  public editDomain(inDomain: DomainDefModel) {
    this.router.navigate(['sysadmin/editdomain', inDomain.key]);
  }

  public newDomain() {
    this.router.navigate(['sysadmin/editdomain', '']);
  }

}
