import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { SettingsService } from '../services/settings.service';
import { RolesService } from '../services/roles.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router, private settingsService: SettingsService,
    private rolesService: RolesService) {

  }

  /**
   * If the user is logged in and has a valid domain, the component may be 'activated'/shown
   * This method uses mergeMap:
   * this method merges the two mappings in the method to one answer (one boolean)
   * 
   * @param next 
   * @param state 
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.getLoggedInUser().pipe(
      mergeMap(user => {
        if (user) {
          return this.rolesService.detectSysAdmin(user.email, '', '', '').pipe(
            mergeMap(isSysAdmin => {
              if (isSysAdmin) {
                return of(true);
              } else {
                return this.authService.validateDomain(user).pipe(
                  mergeMap((validatedDomain) => {
                    if (validatedDomain) { 
                      return of(true);
                    } else {
               //       console.log('user validation');
                      return this.authService.validateAllowedUser(user.email).pipe(
                        map(validatedUser => {
                          if (validatedUser) {
                            return true;
                          } else { 
                            this.authService.signOut();
                            this.router.navigate(['/logout']);
                            return false;
                          }
                        })
                      );
                    }
                  })
                );
              }
            })
          );
        } else {
          this.authService.googleLogin();
          return of(false);
        }
      }));
  }

  /**
   * Checks if the user has a valid email domain
   * @param user 
   */
  validateDomain(user: firebase.User): Observable<boolean> {

    return this.settingsService.retrieveSupportedDomains().pipe(
      map(domainlist => {
        if (domainlist.some(x => user.email.indexOf(x.domain) >= 0)) {
          return true;
        } else {
          this.router.navigate(['/logout']);
          this.authService.signOut();
          return false;
        }
      })
    );


  }

}
