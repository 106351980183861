import { Component, OnInit, Input } from '@angular/core';
import { ArticleModel } from 'src/app/models/article-model';
import { ChannelDefModel } from 'src/app/models/settings/channel-def-model';
import { Router } from '@angular/router';
import { EditorModel } from 'src/app/models/editor-model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-deluxe-head-item',
  templateUrl: './deluxe-head-item.component.html',
  styleUrls: ['./deluxe-head-item.component.css']
})
export class DeluxeHeadItemComponent implements OnInit {
  @Input() articles: ArticleModel[];
  @Input() channelDefs: ChannelDefModel[];
  @Input() isEditor = false;
  @Input() public headArticle = new ArticleModel();
  @Input() inEditor$:Observable<EditorModel>
  constructor(private router: Router) { }

  ngOnInit() {

  }

  openArticle(inArticle: ArticleModel) {
    this.router.navigate(['/articles/view', inArticle.key])
  }

  editArticle(inArticle: ArticleModel) {
    this.router.navigate(['/articles/edit', inArticle.key])
  }

}
