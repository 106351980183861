import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sysadmin-view',
  templateUrl: './sysadmin-view.component.html',
  styleUrls: ['./sysadmin-view.component.css']
})
export class SysadminViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
