import { Injectable } from '@angular/core';
import {AngularFireDatabase, AngularFireList} from '@angular/fire/database';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ApiKeyModel} from '../models/api-key-model';

@Injectable({
  providedIn: 'root'
})
export class ApiKeysService {
  // node name definitions
  static readonly apiKeyNodeName = 'apikeys';

  // Member definitions
  private _apiKeysRef: AngularFireList<any>;

  /**
   * constructor
   *
   * @param {AngularFireDatabase} db
   */
  constructor(private db: AngularFireDatabase) {
    // Create a reference for the apiKey definitions
    this._apiKeysRef = db.list(ApiKeysService.apiKeyNodeName);
  }

  /**
   * retrieveApiKey()
   *
   *
   * @param {string} inKey
   * @returns {Promise<ApiKeyModel>}
   */
  public retrieveApiKey(inKey: string): Promise<ApiKeyModel> {
    // Create a new promise
    const thePromise = new Promise<ApiKeyModel>((resolve, reject) => {
      if (inKey && inKey.length > 0) {

        // Retrieve the data
        const theSubscription = this.db.object(ApiKeysService.apiKeyNodeName + '/' + inKey).snapshotChanges().subscribe(
          inData => {

            // Parse the data
            const theApikey: ApiKeyModel = ApiKeyModel.fromFirebase(inData);

            // Make sure to unsubscribe
            theSubscription.unsubscribe();

            // And resolve the promise
            resolve(theApikey);
          });
      } else {
        const theApikey: ApiKeyModel = new ApiKeyModel();
        resolve(theApikey);
      }
    });

    return thePromise;
  }


  /**
   * retrieveApiKeys()
   *
   * Returns an Observable to the array with ApiKeyModels
   *
   * @returns {Observable<Array<ApiKeyModel>>}
   */
  public retrieveApiKeys(): Observable<Array<ApiKeyModel>> {
    return this._apiKeysRef.snapshotChanges().pipe(
      map(changes =>
        changes.map(apiKeyDefNode => (ApiKeyModel.fromFirebase(apiKeyDefNode))))
    );
  }

  /**
   * storeApiKey()
   *
   * Stores a channelDef in the fireBase database. Will update the existing
   * channelDef if a key is present, will create a new channelDef if the key is empty
   *
   * @param {ApiKeyModel} inApiKey
   */
  public storeApiKey(inApiKey: ApiKeyModel) {

    // Is it an existing defintions or a new one
    if (inApiKey.hasKey()) {

      // Update the existing node
      this._apiKeysRef.update(inApiKey.key, inApiKey.toFirebase());
    } else {

      // Create a new node
      this._apiKeysRef.push(inApiKey.toFirebase());
    }
  }

  /**
   * removeApiKey()
   *
   * Removes an apiKey from the database
   *
   * @param {ApiKeyModel} inApiKey
   */
  public removeApiKey(inApiKey: ApiKeyModel) {
    if (inApiKey.hasKey()) {
      this._apiKeysRef.remove(inApiKey.key);
    }
  }
}
