import { Component, OnInit, Input } from '@angular/core';
import { FileService } from '../../../services/file.service';
import { Observable } from 'rxjs';
import { AngularFireUploadTask } from '@angular/fire/storage/task';

@Component({
  selector: 'app-fileupload',
  templateUrl: './fileupload.component.html',
  styleUrls: ['./fileupload.component.css']
})
export class FileUploadComponent implements OnInit {
  @Input() filePath: string = '';
  @Input() accept: string = '';

  private task: AngularFireUploadTask;
  public percentage: Observable<number>;
  public snapshot: Observable<any>;
  public downloadUrl: Observable<string>;
  public files: FileList;


  constructor(private uploadService: FileService) { }

  ngOnInit() {
  }

  startUpload() {
    if (this.files) {
      this.task = this.uploadService.uploadFile(this.filePath, this.files);

      if (this.task) {
        this.percentage = this.task.percentageChanges();
        this.snapshot = this.task.snapshotChanges();
      }

    }
  }

  setTarget(inFileList: FileList) {
    this.files = inFileList;
  }

  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }

}
