import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { RolesService } from '../services/roles.service';
import { AuthService } from '../services/auth.service';
import { mergeMap, map } from 'rxjs/operators';
import { ArticleService } from '../services/article.service';

@Injectable({
  providedIn: 'root'
})
export class EditorGuard implements CanActivate {

  constructor(private rolesService: RolesService,
    private authService: AuthService,
    private router: Router,
    private articleService: ArticleService) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    // TODO: check this method later again if this is right
    /**
     * Checks if user is an editor, if so, then it may activate the editor component
     *
     */
    return this.authService.getLoggedInUser().pipe(
      mergeMap(result => {
        if (result) {
          return this.rolesService.detectEditor(result.email, '', '', '', '').pipe(
            mergeMap(isEditor => {
              if (isEditor) {
                return this.channelCheck(next.params.articleKey);
              } else {
                this.router.navigate(['articles']);
              }
            })
          );
        }
      }));
  }

  /**
   * 
   * @param inArticleKey 
   * 
   * Checks if the user may activate the component, based on the channels the editor is authorized for.
   * If not authorized, the editor is redirected to the articlelist again.
   * TODO: Maybe redirect to an other page which gives some more info (contact administrator to add channels), 
   * after 10 seconds it redirects the editor back to the article list. 
   */
  channelCheck(inArticleKey): Observable<boolean> {
    return this.articleService.retrieveArticleObservable(inArticleKey).pipe(
      mergeMap(article => {
        return this.rolesService.mayEditByChannel(article).pipe(
          map(result => {
            if (result === true) {
              return true;
            } else {
              this.router.navigate(['articles']);
              return false;
            }
          })
        );
      })
    );
  }

}
