import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { MaterialModule } from './modules/material/material.module';
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import 'hammerjs';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule, AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase, AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from '../environments/environment';
import { AngularFireStorage, AngularFireStorageModule } from '@angular/fire/storage';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { NavbarComponent } from './components/navbar/navbar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EditorComponent } from './components/editor/editor.component';
import * as firebase from 'firebase';
import { ArticleService } from './services/article.service';
import { ArticleListViewComponent } from './components/article-list-view/article-list-view.component';
import { ArticlePlainListComponent } from './components/article-list-view/article-plain-list/article-plain-list.component';
import { ArticleItemPlainComponent } from './components/article-list-view/article-plain-list/article-item-plain/article-item-plain.component';
import { ArticleloaderComponent } from './components/articleloader/articleloader.component';
import { AppRoutingModule } from './app-routing.module';
import { SuredialogComponent } from './components/dialogs/suredialog/suredialog.component';
import {SettingsService} from './services/settings.service';
import { LogoutComponent } from './components/logout/logout.component';
import { ChannelDefPipe } from './pipes/channel-def.pipe';
import { AuthService } from './services/auth.service';
import { ViewarticleComponent } from './components/viewarticle/viewarticle.component';
import { ImagepickerdialogComponent } from './components/dialogs/imagepickerdialog/imagepickerdialog.component';
import { ImageselectorComponent } from './components/common/imageselector/imageselector.component';
import { AdminViewComponent } from './components/admin-view/admin-view.component';
import { EditorListComponent } from './components/admin-view/editor-list/editor-list.component';
import { EditorEditorComponent } from './components/admin-view/editor-list/editor-editor/editor-editor.component';
import { ChannelListComponent } from './components/admin-view/channel-list/channel-list.component';
import { ChannelEditComponent } from './components/admin-view/channel-list/channel-edit/channel-edit.component';
import { SysadminViewComponent } from './components/sysadmin-view/sysadmin-view.component';
import { SysadminListComponent } from './components/sysadmin-view/sysadmin-list/sysadmin-list.component';
import { AdminListComponent } from './components/sysadmin-view/admin-list/admin-list.component';
import { ApikeyListComponent } from './components/sysadmin-view/apikey-list/apikey-list.component';
import { SysadminEditorComponent } from './components/sysadmin-view/sysadmin-list/sysadmin-editor/sysadmin-editor.component';
import { AdminEditorComponent } from './components/sysadmin-view/admin-list/admin-editor/admin-editor.component';
import { ApikeyEditorComponent } from './components/sysadmin-view/apikey-list/apikey-editor/apikey-editor.component';
import { DomainListComponent } from './components/sysadmin-view/domain-list/domain-list.component';
import { DomainEditorComponent } from './components/sysadmin-view/domain-list/domain-editor/domain-editor.component';
import {CloudTriggerService} from './services/cloud-trigger.service';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AllowedUserListComponent } from './components/admin-view/allowed-user-list/allowed-user-list.component';
import { AllowedUserEditorComponent } from './components/admin-view/allowed-user-list/allowed-user-editor/allowed-user-editor.component';
import { FileService } from './services/file.service';
import { FileUploadComponent } from './components/tools/fileupload/fileupload.component';
import { DeluxeviewComponent } from './components/deluxeview/deluxeview.component';
import { DeluxeHeadItemComponent } from './components/deluxeview/deluxe-head-item/deluxe-head-item.component';
import { DeluxeOtherItemsComponent } from './components/deluxeview/deluxe-other-items/deluxe-other-items.component';
import { ArticleSearchComponent } from './components/article-search/article-search.component';
import { MayeditPipe } from './pipes/mayedit.pipe';



@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    ArticleListViewComponent,
    ArticlePlainListComponent,
    ArticleItemPlainComponent,
    NavbarComponent,
    EditorComponent,
    ArticleloaderComponent,
    SuredialogComponent,
    LogoutComponent,
    ChannelDefPipe,
    ViewarticleComponent,
    ImagepickerdialogComponent,
    ImageselectorComponent,
    AdminViewComponent,
    EditorListComponent,
    EditorEditorComponent,
    ChannelListComponent,
    ChannelEditComponent,
    SysadminViewComponent,
    SysadminListComponent,
    AdminListComponent,
    ApikeyListComponent,
    SysadminEditorComponent,
    AdminEditorComponent,
    ApikeyEditorComponent,
    DomainListComponent,
    DomainEditorComponent,
    SidenavComponent,
    AllowedUserListComponent,
    AllowedUserEditorComponent,
    FileUploadComponent,
    DeluxeviewComponent,
    DeluxeHeadItemComponent,
    DeluxeOtherItemsComponent,
    ArticleSearchComponent,
    MayeditPipe,
    
  ],
  entryComponents: [SuredialogComponent
  , ImagepickerdialogComponent],
  imports: [
    BrowserModule,
    MaterialModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    FroalaEditorModule.forRoot(), FroalaViewModule.forRoot(), ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [AngularFireAuth,
    AngularFireDatabase, AngularFireStorage],
  bootstrap: [AppComponent]
})
export class AppModule { }
