import { Injectable } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';


@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private afStorage: AngularFireStorage) { }


  public uploadFile(inPath: string, inFileList: FileList, ): AngularFireUploadTask {
    const file = inFileList.item(0);

    const task = this.afStorage.upload(inPath, file, {});
    return task;
  }

}
