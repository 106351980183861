import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '../../../../../node_modules/@angular/material';

@Component({
  selector: 'app-imagepickerdialog',
  templateUrl: './imagepickerdialog.component.html',
  styleUrls: ['./imagepickerdialog.component.css']
})
export class ImagepickerdialogComponent implements OnInit {
  imageArrayLength = 0;
  layoutSize = 100;

  constructor(public dialogRef: MatDialogRef<ImagepickerdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.imageArrayLength = data.images.length;
    this.layoutSize = 100 / this.imageArrayLength;
   // console.log(this.imageArrayLength);
  }

  ngOnInit() {
  }

  closeDialog(inAction: boolean): void {
    this.dialogRef.close(inAction);
  }

  selectImage(inSrc: string) {
    this.dialogRef.close(inSrc);
  }
}
