/**
 * RolesService
 *
 * Some user will have a roles assigned to them which grants them additional privileges:
 *
 * editors:   Editors can create new articles and edit existing onces.
 * admins:    Admins can manage users Editor roles
 * sysAdmins: SysAdmins can manage admins and sysadmins.
 *
 * Whenever the auth status change the function determineRoles should be called.
 * This will collect all roles data for the current user.
 *
 * The getters: isEditor, isAdmin, isSysAdmin can be queried by components to determin privileges.
 */
import { Injectable } from '@angular/core';
import { map, mergeMap, first } from 'rxjs/operators';
import { RoleModel } from '../models/role-model';
import { Observable, Subscription } from 'rxjs';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { EditorModel } from '../models/editor-model';
import { AuthService } from './auth.service';
import { ArticleModel } from '../models/article-model';


@Injectable({
  providedIn: 'root'
})
export class RolesService {
  // node name definitions
  static readonly editorsNodeName = 'roles/editors';      // Node name of the editors node in fireBase
  static readonly adminsNodeName = 'roles/admins';        // Node name of the admins node in fireBase
  static readonly sysadminsNodeName = 'roles/sysadmins';  // Node name of the sysadmin node in fireBase

  private _isEditor: boolean = false;
  private _editor: EditorModel = new EditorModel();
  private _isAdmin: boolean = false;
  private _isSysAdmin: boolean = false;

  private _editorsRef: AngularFireList<any>;
  private _adminsRef: AngularFireList<any>;
  private _sysadminsRef: AngularFireList<any>;
  private _editors$: Subscription;
  private _admins$: Subscription;
  private _sysadmins$: Subscription;

  /**
   * constructor
   *
   * @param {AngularFireDatabase} db
   */
  constructor(private db: AngularFireDatabase, private authService: AuthService) {
    this._editorsRef = db.list(RolesService.editorsNodeName);
    this._adminsRef = db.list(RolesService.adminsNodeName);
    this._sysadminsRef = db.list(RolesService.sysadminsNodeName);
  }

  /**
   * determineRoles()
   *
   * Determins the role(s) of the currently logged in user.
   * Should be called each time the authSTatus changes
   *
   * @param {string} inUid
   */
  public determineRoles(inEmail: string, inUid: string, inName: string, inPhotoUrl: string) {
    // Determin if the user is an editor
    if (this._editors$) {
      this._editors$.unsubscribe();
    }
    this._editors$ = this.detectEditor(inEmail, inUid, inName, inPhotoUrl, '').subscribe((inIsEditor: boolean) => {
      this._isEditor = inIsEditor;
    });

    // Determine if the user is an admin
    if (this._admins$) {
      this._admins$.unsubscribe();
    }
    this._admins$ = this.detectAdmin(inEmail, inUid, inName, inPhotoUrl).subscribe((inIsAdmin: boolean) => {
      this._isAdmin = inIsAdmin;
    });

    // Determine if the user is a sysadmin
    if (this._sysadmins$) {
      this._sysadmins$.unsubscribe();
    }
    this._sysadmins$ = this.detectSysAdmin(inEmail, inUid, inName, inPhotoUrl).subscribe((inIsSysAdmin: boolean) => {
      this._isSysAdmin = inIsSysAdmin;
    });
  }

  /**
   * detectEditor()
   *
   * Returns a promise of true (the users is an editor) or false (user is not an editor)
   *
   * @param {string} inUid
   * @returns {Promise<boolean>}
   */
  public detectEditor(inEmail: string, inUid: string, inName: string, inPhotoUrl: string, inChannelDefKey: string): Observable<boolean> {
    const theObservable: Observable<boolean> = new Observable((inObserver) => {
  
      this.db.list(RolesService.editorsNodeName, ref =>
        ref.orderByChild(RoleModel.email)
          .equalTo(inEmail)).snapshotChanges().subscribe(changes => {
            if (changes.length > 0) {
              this._editor = EditorModel.fromFirebase(changes[0]);

              if (this.needsUpdate(this._editor, inUid, inName, inPhotoUrl)) {
                this.storeEditor(this._editor);
              }

              let isEditor: boolean = changes.length > 0;

              // Is there a specific channel defined
              if (isEditor && inChannelDefKey && inChannelDefKey.length > 0) {

                isEditor = this._editor.hasAccess(inChannelDefKey);
              }

              inObserver.next(isEditor);
            } else {
              inObserver.next(false);
            }
          });
    });
  
    return theObservable;
  }


  /**
   * retrieveEditor()
   *
   * Returns an editor with inKey from the database
   *
   * @param {string} inKey
   * @returns {Promise<EditorModel>}
   */
  public retrieveEditor(inKey: string): Promise<EditorModel> {
    // Create a new promise
    const thePromise = new Promise<EditorModel>((resolve, reject) => {
      if (inKey && inKey.length > 0) {

        // Retrieve the data
        const theSubscription = this.db.object(RolesService.editorsNodeName + '/' + inKey).snapshotChanges().subscribe(
          inData => {

            // Parse the data
            const theEditor: EditorModel = EditorModel.fromFirebase(inData);

            // Make sure to unsubscribe
            theSubscription.unsubscribe();

            // And resolve the promise
            resolve(theEditor);
          });
      } else {

        // Empty key received, return a new editor
        const theEditor = new EditorModel();
        resolve(theEditor);
      }
    });

    return thePromise;
  }

  /**
   * 
   * getLoggedInEditor()
   * 
   * Returns the editor(model) that is logged in 
   * 
   */
  getLoggedInEditor(): Observable<EditorModel[]> {
    return this.authService.getLoggedInUser().pipe(
      mergeMap(user => {
        return this.db.list(RolesService.editorsNodeName,
          ref => ref.orderByChild(RoleModel.email).equalTo(user.email)).snapshotChanges().pipe(
            map(items => {
              return items.map(item => {
                return EditorModel.fromFirebase(item);
              });
            })
          );
      })
    );
  }


  /**
   * 
   * 
   * @param inEmail 
   * 
   * Returns the editor by email
   */
  getEditor(inEmail) {
    return this.db.list(RolesService.editorsNodeName, ref => ref.orderByChild(RoleModel.email).equalTo(inEmail)).snapshotChanges().pipe(
      map(items => {

        return items.map(item => {
          return EditorModel.fromFirebase(item);
        });
      })
    );
  }

  /**
   * 
   * @param inArticle 
   * 
   * Checks if the logged in editor may edit by channel
   */
  mayEditByChannel(inArticle: ArticleModel): Observable<boolean> {
    return this.getLoggedInEditor().pipe(
      map(editor => {
        if (editor[0].allChannels === true) {
          return true;
        } else if (inArticle.channels.length > 0) {
          return editor[0].channels.some(x => inArticle.channels.indexOf(x) >= 0);
        } else if (inArticle.channels.length === 0) {
          return true;
        } else {
          return false;
        }
      })
    );



  }



  /**
   * storeEditor()
   *
   * Stores an Editor in the fireBase database. Will update the existing
   * RoleModel if a key is present, will create a new channelDef if the key is empty
   *
   * @param {RoleModel} inEditor
   */
  public storeEditor(inEditor: EditorModel) {
    if (inEditor.hasKey()) {
      // Update the existing node
      this._editorsRef.update(inEditor.key, inEditor.toFirebase());
    } else {

      // Create a new node
      this._editorsRef.push(inEditor.toFirebase());
    }
  }

  /**
   * removeEditor()
   *
   * Removes an editor from the database
   *
   * @param {ChannelDefModel} inChannelDef
   */
  public removeEditor(inEditor: EditorModel) {
    if (inEditor.hasKey()) {
      this._editorsRef.remove(inEditor.key);
    }
  }

  /**
   * getEditorsList()
   *
   * Returns an Observable for a list of Editors
   *
   * @returns {Observable<Array<EditorModel>>}
   */
  public getEditorsList(): Observable<Array<EditorModel>> {
    return this._editorsRef.snapshotChanges().pipe(
      map(changes =>
        changes.map(theEditorNode =>
          (EditorModel.fromFirebase(theEditorNode))
        )));
  }

  /**
   * detectAdmin()
   *
   * Returns a promise of true (the users is an admin) or false (user is not an admin)
   *
   * @param {string} inUid
   * @returns {Promise<boolean>}
   */
  public detectAdmin(inEmail: string, inUid: string, inName: string, inPhotoUrl: string): Observable<boolean> {
    const theObservable: Observable<boolean> = new Observable((inObserver) => {
  
      this.db.list(RolesService.adminsNodeName, ref =>
        ref.orderByChild(RoleModel.email)
          .equalTo(inEmail)).snapshotChanges().subscribe(changes => {

            if (changes.length > 0) {
              const theRoleModel = RoleModel.fromFirebase(changes[0]);

              if (this.needsUpdate(theRoleModel, inUid, inName, inPhotoUrl)) {
                this.storeAdmin(theRoleModel);
              }

              inObserver.next(changes.length > 0);
            } else {
              inObserver.next(false);
            }
          });
    });
  
    return theObservable;
  }
  
  /**
   * retrieveAdmin()
   *
   *
   * @param {string} inKey
   * @returns {Promise<RoleModel>}
   */
  public retrieveAdmin(inKey: string): Promise<RoleModel> {
    // Create a new promise
    const thePromise = new Promise<RoleModel>((resolve, reject) => {
      if (inKey && inKey.length > 0) {

        // Retrieve the data
        const theSubscription = this.db.object(RolesService.adminsNodeName + '/' + inKey).snapshotChanges().subscribe(
          inData => {

            // Parse the data
            const theAdmin: RoleModel = RoleModel.fromFirebase(inData);

            // Make sure to unsubscribe
            theSubscription.unsubscribe();

            // And resolve the promise
            resolve(theAdmin);
          });
      } else {

        // Empty key received, return a new editor
        const theAdmin = new RoleModel();
        resolve(theAdmin);
      }
    });

    return thePromise;
  }

  /**
   * storeAdmin()
   *
   * Stores an Admin in the fireBase database. Will update the existing
   * Admin if a key is present, will create a new Admin if the key is empty
   *
   * @param {RoleModel} inAdmin
   */
  public storeAdmin(inAdmin: RoleModel) {
    if (inAdmin.hasKey()) {
      // Update the existing node
      this._adminsRef.update(inAdmin.key, inAdmin.toFirebase());
    } else {

      // Create a new node
      this._adminsRef.push(inAdmin.toFirebase());
    }
  }

  /**
   * removeAdmin()
   *
   * Removes an admin from the database
   *
   * @param {RoleModel} inAdmin
   */
  public removeAdmin(inAdmin: RoleModel) {
    if (inAdmin.hasKey()) {
      this._adminsRef.remove(inAdmin.key);
    }
  }

  /**
   * getAdminsList()
   *
   * Returns an Observable for a list of Admins
   *
   * @returns {Observable<Array<RoleModel>>}
   */
  public getAdminsList(): Observable<Array<RoleModel>> {
    return this._adminsRef.snapshotChanges().pipe(
      map(changes =>
        changes.map(theAdminNode =>
          (RoleModel.fromFirebase(theAdminNode))
        )));
  }


  /**
   * detectSysAdmin()
   *
   * Returns a promise of true (the users is a sysadmin) or false (user is not a sysadmin)
   *
   * @param {string} inUid
   * @returns {Promise<boolean>}
   */
  public detectSysAdmin(inEmail: string, inUid: string, inName: string, inPhotoUrl: string): Observable<boolean> {
    const theObservable: Observable<boolean> = new Observable((inObserver) => {
  
      this.db.list(RolesService.sysadminsNodeName, ref =>
        ref.orderByChild(RoleModel.email)
          .equalTo(inEmail)).snapshotChanges().subscribe(changes => {

            if (changes.length > 0) {
              const theRoleModel = RoleModel.fromFirebase(changes[0]);

              if (this.needsUpdate(theRoleModel, inUid, inName, inPhotoUrl)) {
                this.storeSysAdmin(theRoleModel);
              }

              inObserver.next(changes.length > 0);
            } else {
              inObserver.next(false);
            }
          });
    });
  
    return theObservable;
  }
   

  /**
   * retrieveSysAdmin()
   *
   *
   *
   * @param {string} inKey
   * @returns {Promise<RoleModel>}
   */
  public retrieveSysAdmin(inKey: string): Promise<RoleModel> {
    // Create a new promise
    const thePromise = new Promise<RoleModel>((resolve, reject) => {
      if (inKey && inKey.length > 0) {

        // Retrieve the data
        const theSubscription = this.db.object(RolesService.sysadminsNodeName + '/' + inKey).snapshotChanges().subscribe(
          inData => {

            // Parse the data
            const theSysAdmin: RoleModel = RoleModel.fromFirebase(inData);

            // Make sure to unsubscribe
            theSubscription.unsubscribe();

            // And resolve the promise
            resolve(theSysAdmin);
          });
      } else {

        // Empty key received, return a new editor
        const theSysAdmin = new RoleModel();
        resolve(theSysAdmin);
      }
    });

    return thePromise;
  }

  /**
   * storeSysAdmin()
   *
   * Stores a sysAdmin in the fireBase database. Will update the existing
   * sysAdmin if a key is present, will create a new sysadmin if the key is empty
   *
   * @param {RoleModel} inSysAdmin
   */
  public storeSysAdmin(inSysAdmin: RoleModel) {
    if (inSysAdmin.hasKey()) {

      // Update the existing node
      this._sysadminsRef.update(inSysAdmin.key, inSysAdmin.toFirebase());
    } else {

      // Create a new node
      this._sysadminsRef.push(inSysAdmin.toFirebase());
    }
  }

  /**
   * removeSysAdmin()
   *
   * Removes an editor from the database
   *
   * @param {ChannelDefModel} inSysAdmin
   */
  public removeSysAdmin(inSysAdmin: RoleModel) {
    if (inSysAdmin.hasKey()) {
      this._sysadminsRef.remove(inSysAdmin.key);
    }
  }

  /**
   * getSysAdminList()
   *
   * Returns an Observable for a list of Editors
   *
   * @returns {Observable<Array<RoleModel>>}
   */
  public getSysAdminList(): Observable<Array<RoleModel>> {
    return this._sysadminsRef.snapshotChanges().pipe(
      map(changes =>
        changes.map(theSysAdminNode =>
          (RoleModel.fromFirebase(theSysAdminNode))
        )));
  }

  /**
   * needsUpdate()
   *
   * Returns true if the roleModel firebase data needs to be updated.
   * Will update the inUser data so it can be saved
   *
   * @param {RoleModel} inUser
   * @param {string} inUid
   * @param {string} inName
   * @param {string} inPhotoUrl
   * @returns {boolean}
   */
  private needsUpdate(inUser: RoleModel, inUid: string, inName: string, inPhotoUrl: string): boolean {
    let needsUpdate: boolean = false;

    // Does the uid need updating?
    if (inUid && inUid.length && inUser.uid !== inUid) {
      inUser.uid = inUid;
      needsUpdate = true;
    }

    // Does the name need updating?
    if (inName && inName.length && inUser.name !== inName) {
      inUser.name = inName;
      needsUpdate = true;
    }

    // Does the photo need updating?
    if (inPhotoUrl && inPhotoUrl.length && inUser.photoUrl !== inPhotoUrl) {
      inUser.photoUrl = inPhotoUrl;
      needsUpdate = true;
    }

    return needsUpdate;
  }

  /*

  GETTERS AND SETTERS

 */

  get isEditor(): boolean {
    return this._isEditor;
  }

  get isAdmin(): boolean {
    return this._isAdmin;
  }

  get isSysAdmin(): boolean {
    return this._isSysAdmin;
  }

  get editor(): EditorModel {
    return this._editor;
  }

  /*
   * Temoporary code
   */
  public initUsers() {
    const mirko: RoleModel = new RoleModel();
    mirko.name = 'Mirko Pelgrom';
    mirko.email = 'm.pelgrom@icco.nl';

    const yuri: RoleModel = new RoleModel();
    yuri.name = 'Yuri Vermeire';
    yuri.email = 'y.vermeire@icco.nl';

    const aalt: RoleModel = new RoleModel();
    aalt.name = 'Aalt van de Glind';
    aalt.email = 'a.vandeglind@pkn.nl';

    // this.storeEditor(mirko);
    // this.storeEditor(yuri);
    // this.storeEditor(aalt);

    this.storeAdmin(mirko);
    this.storeAdmin(yuri);
    this.storeAdmin(aalt);

    this.storeSysAdmin(mirko);
    this.storeSysAdmin(yuri);
    this.storeSysAdmin(aalt);
  }
}
