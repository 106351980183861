import { Component } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RoleModel} from '../../../../models/role-model';
import {Subscription} from 'rxjs';
import {RolesService} from '../../../../services/roles.service';

import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-sysadmin-editor',
  templateUrl: './sysadmin-editor.component.html',
  styleUrls: ['./sysadmin-editor.component.css']
})
export class SysadminEditorComponent {
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  matcher = new MyErrorStateMatcher();

  public sysadmin: RoleModel = new RoleModel();
  private subscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private rolesService: RolesService) {

      // Gets the key from the route parameters
      this.subscription = this.route.params.subscribe(params => {
        this.rolesService.retrieveSysAdmin(params['key']).then((result: RoleModel) => {
          this.sysadmin = result;
        });
      });
    }



  public saveSysadmin() {
    this.rolesService.storeSysAdmin(this.sysadmin);
    this.router.navigate(['sysadmin']);
  }

  public handleCancel() {
    this.router.navigate(['sysadmin']);
  }

  public HandleDelete() {
    // if (this.sysadmin.hasKey()) {
    //   this.rolesService.removeSysAdmin(this.sysadmin);
    //   this.router.navigate(['sysadmin']);
    // }
  }
}
