/**
 * RoleModel
 *
 * Some users can have the Editor/Admin/SysAdmin roles. These users are stored in the role node of fireBase, so they can be identified
 * after login.
 *
 */

import {isNullOrUndefined} from 'util';



export class RoleModel {
  static readonly email = 'email';

  private _key: string = '';            // fireBase key for this node
  private _name: string = '';           // Name of the user
  private _email: string = '';          // Email address of the user
  private _uid: string = '';            // User id of the user
  private _photoUrl: string = '';       // Url to the user's photo

  /**
   * fromFirebase()
   *
   * Returns an ArticleModel constructed from fireBase data
   *
   * @param inFireNode          any   data as received from fireBase
   * @returns {ArticleModel}
   */
  public static fromFirebase(inFireNode: any): RoleModel {
    // Create a new empty model
    const theModel: RoleModel = new RoleModel();

    theModel.fromFirebaseData(inFireNode);

    return theModel;
  }

  /**
   * RoleModel()
   *
   * @constructor
   */
  public RoleModel() {

  }

  /**
   * fromFirebaseData()
   *
   * Parses the fireBase data
   *
   * @param inFireNode
   */
  public fromFirebaseData(inFireNode: any) {
    if (!isNullOrUndefined(inFireNode.key)) {
      // Store the key to the firebaseData
      this._key = inFireNode.key;

      // Retirieve the payload of the firebase data
      const thePayload = inFireNode.payload.val();

      // Decode the node elements
      if (!isNullOrUndefined(thePayload.name)) {
        this._name = thePayload.name;
      }
      if (!isNullOrUndefined(thePayload.email)) {
        this._email = thePayload.email;
      }
      if (!isNullOrUndefined(thePayload.uid)) {
        this._uid = thePayload.uid;
      }
      if (!isNullOrUndefined(thePayload.photoUrl)) {
        this._photoUrl = thePayload.photoUrl;
      }
    }
  }

  /**
   * toFirebase()
   *
   * Returns a fireBase node with data from the object
   *
   * @returns {any}
   */
  public toFirebase(): any {
    const theObj: any = {
      name:          this._name,
      email:         this._email,
      uid:           this._uid,
      photoUrl:      this._photoUrl
    };

    return theObj;
  }

  /**
   * hasKey()
   *
   * Returns true if the model has a key (thus comes from the fireBase databae,
   * and false when no key is present (thus never been saved).
   *
   * @returns {boolean}
   */
  public hasKey(): boolean {
    return !isNullOrUndefined(this._key) && this._key.length > 0;
  }

  /*

  GETTERS AND SETTERS

 */


  get key(): string {
    return this._key;
  }

  set key(value: string) {
    this._key = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }


  get uid(): string {
    return this._uid;
  }

  set uid(value: string) {
    this._uid = value;
  }

  get photoUrl(): string {
    return this._photoUrl;
  }

  set photoUrl(value: string) {
    this._photoUrl = value;
  }
}
