import { Component, OnInit } from '@angular/core';
import {RoleModel} from '../../../models/role-model';
import {Router} from '@angular/router';
import {RolesService} from '../../../services/roles.service';

@Component({
  selector: 'app-admin-list',
  templateUrl: './admin-list.component.html',
  styleUrls: ['./admin-list.component.css']
})
export class AdminListComponent implements OnInit {
  public admins: Array<RoleModel> = [];

  constructor(
    private rolesService: RolesService,
    private router: Router
  ) {
      this.rolesService.getAdminsList().subscribe((adminList: Array<RoleModel>) => {
        this.admins = adminList;
      });
    }

  ngOnInit() {
  }

  public editAdmin(inAdmin: RoleModel) {
    this.router.navigate(['sysadmin/editadmin', inAdmin.key]);
  }

  public newAdmin() {
    this.router.navigate(['sysadmin/editadmin', '']);
  }

}
