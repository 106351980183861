import { Component, OnInit } from '@angular/core';
import { RolesService } from '../../services/roles.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit {

  constructor(public rolesService: RolesService, public router: Router) { }

  ngOnInit() {
  }

  newArticle() {
    this.router.navigate(['articles/edit', '']);
  }

  toArticleList() {
    this.router.navigate(['articles']);
  }

  toAdmin() {
    this.router.navigate(['admin']);
  }

  toSysAdmin() {
    this.router.navigate(['sysadmin']);
  } 


}
