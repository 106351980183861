import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ArticleListViewComponent } from './components/article-list-view/article-list-view.component';
import { ArticleloaderComponent } from './components/articleloader/articleloader.component';
import { LogoutComponent } from './components/logout/logout.component';
import { AuthGuard } from './guards/auth.guard';
import { ViewarticleComponent } from './components/viewarticle/viewarticle.component';
import { EditorGuard } from './guards/editor.guard';
import {AdminViewComponent} from './components/admin-view/admin-view.component';
import {AdminGuard} from './guards/admin.guard';
import {EditorEditorComponent} from './components/admin-view/editor-list/editor-editor/editor-editor.component';
import {ChannelEditComponent} from './components/admin-view/channel-list/channel-edit/channel-edit.component';
import {SysadminViewComponent} from './components/sysadmin-view/sysadmin-view.component';
import {SysadminEditorComponent} from './components/sysadmin-view/sysadmin-list/sysadmin-editor/sysadmin-editor.component';
import {AdminEditorComponent} from './components/sysadmin-view/admin-list/admin-editor/admin-editor.component';
import {ApikeyEditorComponent} from './components/sysadmin-view/apikey-list/apikey-editor/apikey-editor.component';
import {DomainEditorComponent} from './components/sysadmin-view/domain-list/domain-editor/domain-editor.component';
import {SysadminGuard} from './guards/sysadmin.guard';
import { AllowedUserEditorComponent } from './components/admin-view/allowed-user-list/allowed-user-editor/allowed-user-editor.component';

// Contains all routes to certain components
const routes: Routes = [
  { path: 'articles', component: ArticleListViewComponent, canActivate: [AuthGuard] },
  { path: 'articles/edit/:articleKey', component: ArticleloaderComponent, canActivate: [AuthGuard, EditorGuard] },
  { path: 'articles/view/:articleKey', component: ViewarticleComponent, canActivate: [AuthGuard] },
  { path: 'admin', component: AdminViewComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'admin/edit/:key', component: EditorEditorComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'admin/editchannel/:key', component: ChannelEditComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'admin/editalloweduser/:key', component: AllowedUserEditorComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'sysadmin', component: SysadminViewComponent, canActivate: [AuthGuard, SysadminGuard] },
  { path: 'sysadmin/editsysadmin/:key', component: SysadminEditorComponent, canActivate: [AuthGuard, SysadminGuard] },
  { path: 'sysadmin/editadmin/:key', component: AdminEditorComponent, canActivate: [AuthGuard, SysadminGuard] },
  { path: 'sysadmin/editapikey/:key', component: ApikeyEditorComponent, canActivate: [AuthGuard, SysadminGuard] },
  { path: 'sysadmin/editdomain/:key', component: DomainEditorComponent, canActivate: [AuthGuard, SysadminGuard] },
  { path: 'logout', component: LogoutComponent },
  { path: '', redirectTo: '/articles', pathMatch: 'full' }
];
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],

  declarations: []
})
export class AppRoutingModule { }
