import { Component, OnInit } from '@angular/core';
import {EditorModel} from '../../../../models/editor-model';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {SettingsService} from '../../../../services/settings.service';
import {ChannelDefModel} from '../../../../models/settings/channel-def-model';

@Component({
  selector: 'app-channel-edit',
  templateUrl: './channel-edit.component.html',
  styleUrls: ['./channel-edit.component.css']
})
export class ChannelEditComponent implements OnInit {
  
  public channelDef: ChannelDefModel = new ChannelDefModel();

  private subscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private settingsService: SettingsService)
  {    // Gets the key from the route parameters
    this.subscription = this.route.params.subscribe(params => {
      this.settingsService.retrieveChannelDef(params['key']).then((result: ChannelDefModel) => {
        this.channelDef = result;
      });
    });
  }

  ngOnInit() {
  }

  public isValid(): boolean {
    let isValid = true;

    if (this.channelDef.name.length <= 0) {
      isValid = false;
    }
    if (this.channelDef.label.length <= 0) {
      isValid = false;
    }


    return isValid;
  }

  public saveEditor() {
    this.settingsService.storeChannelDef(this.channelDef);
    this.router.navigate(['admin']);
  }

  public handleCancel() {
    this.router.navigate(['admin']);
  }

  public HandleDelete() {
    if (this.channelDef.hasKey()) {
      this.settingsService.removeChannelDef(this.channelDef);
      this.router.navigate(['admin']);
    }
  }
}
