import { Component } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RolesService} from '../../../../services/roles.service';
import {Subscription} from 'rxjs';
import {RoleModel} from '../../../../models/role-model';
import {ErrorStateMatcher} from '@angular/material/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-admin-editor',
  templateUrl: './admin-editor.component.html',
  styleUrls: ['./admin-editor.component.css']
})
export class AdminEditorComponent {
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  matcher = new MyErrorStateMatcher();

  public admin: RoleModel = new RoleModel();
  private subscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private rolesService: RolesService
  ) {
    // Gets the key from the route parameters
    this.subscription = this.route.params.subscribe(params => {
      this.rolesService.retrieveAdmin(params['key']).then((result: RoleModel) => {
        this.admin = result;
      });
    });
  }

  public saveAdmin() {
    this.rolesService.storeAdmin(this.admin);
    this.router.navigate(['sysadmin']);
  }

  public handleCancel() {
    this.router.navigate(['sysadmin']);
  }

  public HandleDelete() {
    if (this.admin.hasKey()) {
      this.rolesService.removeAdmin(this.admin);
      this.router.navigate(['sysadmin']);
    }
  }
}
