/**
 * ArticleModel()
 *
 * Models a single article as it is stored in the fireBase database
 *
 * The title, main image and summary are used to generate the various lists.
 *
 * The content of the article not NOT stored with the rest of the article data.
 * Reason for this is that the content may be quite large (compared to the article meta data
 * By storing the content separately, the ArticleModel can be used for generating lists of articles
 * Only when an Article will be opened (or edited) the actual content is required.
 */

import { isNullOrUndefined } from 'util';

export class ArticleModel {
  private _key: string = '';                // The fireBase key for this article
  private _title: string = '';              // the title as it appears in the lists
  private _contentKey: string = '';         // a fireBase key into the content node. Where the content is stored*
  private _summary: string = '';            // A small summary of the article (max 280 chars)
  private _mainImageRef: string = '';       // A reference to the main image used for lists
  private _channels: Array<string> = [];    // List of channelDef.keys, linking the article to one or more channels
  private _imageUrls: Array<string> = [];
  private _creationDateInMs: number = 0;    // Date the article was created in milliseconds
  private _createdBy: string = '';          // email address of the editor that created the article
  private _lastUpdateDateInMs: number = 0;  // Date the article was last updated
  private _lastUpdatedBy: string = '';      // email address of the editor that last updated the article
  private _published: boolean = false;      // Is the article published or not


  private _content: string = '';            // The article content. This field is NOT stored with the article in fireBase!!!
  private _viewCount: number = 0;           // The number of times the article is viewed. This field is NOT stored with the article

  /**
   * fromFirebase()
   *
   * Returns an ArticleModel constructed from fireBase data
   *
   * @param inFireNode          any   data as received from fireBase
   * @returns {ArticleModel}
   */
  public static fromFirebase(inFireNode: any): ArticleModel {
    // Create a new empty model
    const theModel: ArticleModel = new ArticleModel();

    if (!isNullOrUndefined(inFireNode.key)) {
      // Store the key to the firebaseData
      theModel._key = inFireNode.key;

      // Retrieve the payload of the firebase data
      const thePayload = inFireNode.payload.val();

      // Decode the node elements
      if (!isNullOrUndefined(thePayload.title)) {
        theModel._title = thePayload.title;
      }
      if (!isNullOrUndefined(thePayload.contentKey)) {
        theModel._contentKey = thePayload.contentKey;
      }
      if (!isNullOrUndefined(thePayload.summary)) {
        theModel._summary = thePayload.summary;
      }
      if (!isNullOrUndefined(thePayload.mainImageRef)) {
        theModel._mainImageRef = thePayload.mainImageRef;
      }
      if (!isNullOrUndefined(thePayload.channels)) {
        theModel._channels = thePayload.channels;
      }
      if (!isNullOrUndefined(thePayload.imageUrls)) {
        theModel._imageUrls = thePayload.imageUrls;
      }

      if (!isNullOrUndefined(thePayload.creationDateInMs)) {
        theModel._creationDateInMs = thePayload.creationDateInMs;
      }
      if (!isNullOrUndefined(thePayload.createdBy)) {
        theModel._createdBy = thePayload.createdBy;
      }
      if (!isNullOrUndefined(thePayload.lastUpdateDateInMs)) {
        theModel._lastUpdateDateInMs = thePayload.lastUpdateDateInMs;
      }
      if (!isNullOrUndefined(thePayload.lastUpdatedBy)) {
        theModel._lastUpdatedBy = thePayload.lastUpdatedBy;
      }
      if (!isNullOrUndefined(thePayload.published)) {
        theModel._published = thePayload.published;
      }
    }

    return theModel;
  }

  /**
   * ArticleModel()
   *
   * @constructor
   */
  public ArticleModel() {

  }

  /**
   * toFirebase()
   *
   * Returns a fireBase node with data from the object
   *
   * @returns {any}
   */
  public toFirebase(): any {
    return {
      title: this._title,
      contentKey: this._contentKey,
      summary: this._summary,
      mainImageRef: this._mainImageRef,
      channels: this._channels,
      imageUrls: this._imageUrls,
      creationDateInMs: this._creationDateInMs,
      createdBy: this._createdBy,
      lastUpdateDateInMs: this._lastUpdateDateInMs,
      lastUpdatedBy: this._lastUpdatedBy,
      published: this._published
    };
  }

  /**
   * hasKey()
   *
   * Returns true if the model has a key (thus comes from the fireBase database,
   * and false when no key is present (thus never been saved).
   *
   * @returns {boolean}
   */
  public hasKey(): boolean {
    return !isNullOrUndefined(this._key) && this._key.length > 0;
  }


  /**
   * addChannel()
   *
   * Adds a publication channel to the article
   *
   * @param {string} inChannelDefKey      the key of the channel definition to add
   */
  public addChannel(inChannelDefKey: string) {
    // Find the index of the key
    const theIndex = this.channels.indexOf(inChannelDefKey);

    // If it is NOT present, add it
    if (theIndex < 0) {
      this.channels.push(inChannelDefKey);
    }
  }

  /**
   * removeChannel()
   *
   * Removes a publication channel from the article
   *
   * @param {string} inChannelDefKey      the key of the channel definition to remove
   */
  public removeChannel(inChannelDefKey: string) {

    // Find the index of the key
    const theIndex = this.channels.indexOf(inChannelDefKey);

    // If it is present, remove it
    if (theIndex >= 0) {
      this.channels.splice(theIndex, 1);
    }
  }

  /**
   * The image url list contains all images of an array
   * This method adds an image to the image array\
   * Also sets the main image reference to the first image in the array
   *
   * addImageUrl()
   * @param inImageUrl
   */
  public addImageUrl(inImageUrl: string) {
    const theIndex = this.imageUrls.indexOf(inImageUrl);

    if (theIndex < 0) {
      this.imageUrls.push(inImageUrl);
      this.setMainImage();
    }
  }

  /**
   * The image url list contains all images of an array
   * This method remove an image from the image array
   * Also sets the new image reference if present
   *
   * removeImageUrl()
   * @param inImageUrl
   */
  public removeImageUrl(inImageUrl: string) {
    const theIndex = this.imageUrls.indexOf(inImageUrl);

    if (theIndex >= 0) {
      this.imageUrls.splice(theIndex, 1);
      this.setMainImage();
    }
  }

  /**
   * setMainImage()
   *
   * Sets the main image reference from the imageUrls list
   *
   */
  private setMainImage() {
    if (this.imageUrls.length > 0) {
      this.mainImageRef = this.imageUrls[0];
    } else {
      this.mainImageRef = '';
    }
  }
  /*

  GETTERS AND SETTERS

   */

  get key(): string {
    return this._key;
  }

  set key(value: string) {
    this._key = value;
  }

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  get contentKey(): string {
    return this._contentKey;
  }

  set contentKey(value: string) {
    this._contentKey = value;
  }

  get summary(): string {
    return this._summary;
  }

  set summary(value: string) {
    this._summary = value;
  }

  get mainImageRef(): string {
    return this._mainImageRef;
  }

  set mainImageRef(value: string) {
    this._mainImageRef = value;
  }

  get content(): string {
    return this._content;
  }

  set content(value: string) {
    this._content = value;
  }

  get viewCount(): number {
    return this._viewCount;
  }

  set viewCount(value: number) {
    this._viewCount = value;
  }

  get channels(): Array<string> {
    return this._channels;
  }

  set channels(value: Array<string>) {
    this._channels = value;
  }

  get imageUrls(): Array<string> {
    return this._imageUrls;
  }


  set imageUrls(value: Array<string>) {
    this._imageUrls = value;
  }


  get creationDateInMs(): number {
    return this._creationDateInMs;
  }

  get creationDate(): Date {
    return new Date(this._creationDateInMs);
  }

  set creationDateInMs(value: number) {
    this._creationDateInMs = value;
  }

  get createdBy(): string {
    return this._createdBy;
  }

  set createdBy(value: string) {
    this._createdBy = value;
  }

  get lastUpdateDateInMs(): number {
    return this._lastUpdateDateInMs;
  }

  get lastUpdateDate(): Date {
    return new Date(this._lastUpdateDateInMs);
  }

  set lastUpdateDateInMs(value: number) {
    this._lastUpdateDateInMs = value;
  }

  get lastUpdatedBy(): string {
    return this._lastUpdatedBy;
  }

  set lastUpdatedBy(value: string) {
    this._lastUpdatedBy = value;
  }

  get published(): boolean {
    return this._published;
  }

  set published(value: boolean) {
    this._published = value;
  }
}
