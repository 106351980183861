import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { SettingsService } from './settings.service';
import { map } from '../../../node_modules/rxjs/operators';
import { AllowedUserModel } from '../models/allowed-user-model';





@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public loggedInUser;
  authState: any;


  constructor(
    private afAuth: AngularFireAuth, private router: Router, private settingsService: SettingsService) {
    this.authState = afAuth.auth;
  }

  /**
   * googleLogin()
   * 
   * This method can be used to sign in with Google via a redirect
   */
  googleLogin() {
    return this.authState.signInWithRedirect(new firebase.auth.GoogleAuthProvider);
  }

  /**
   * getLoggedInUser()
   * 
   * Get the logged in user in Firebase
   */
  public getLoggedInUser(): Observable<firebase.User> {
    return this.afAuth.authState;
  }

  /**
   * signOut()
   * 
   * Signs out the user 
   */
  signOut() {
    this.afAuth.auth.signOut().then(() => {
      this.router.navigate(['/logout']);
    });
  }

  validateDomain(user: firebase.User): Observable<boolean> {

    return this.settingsService.retrieveSupportedDomains().pipe(
      map(domainlist => {
        if (domainlist.some(x => user.email.indexOf(x.domain) >= 0)) {
          return true;
        } else {
          return false;
        }
      })
    );


  }

  validateAllowedUser(inEmail: string): Observable<boolean> {
    return this.settingsService.getAllowedUsers().pipe(
      map(allowedUsers => {
        if (allowedUsers.some((emailobj: AllowedUserModel) => inEmail.indexOf(emailobj.email) >= 0)) {
          return true;
        } else {
          return false;
        }
      })
    ); 
  }

  // setLoggedInUser() {
  //   this.afAuth.authState.subscribe(result => {
  //     this.loggedInUser = result;
  //   });
  // }


}
