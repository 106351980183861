import { Component, OnInit, Input } from '@angular/core';
import { ArticleModel } from 'src/app/models/article-model';
import { ChannelDefModel } from 'src/app/models/settings/channel-def-model';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { EditorModel } from 'src/app/models/editor-model';

@Component({
  selector: 'app-deluxe-other-items',
  templateUrl: './deluxe-other-items.component.html',
  styleUrls: ['./deluxe-other-items.component.css']
})
export class DeluxeOtherItemsComponent implements OnInit {
  @Input() articles:ArticleModel[];
  @Input() channelDefs:ChannelDefModel[];
  @Input() isEditor = false;
  @Input() inEditor$:Observable<EditorModel>

  constructor(private router:Router) { }

  ngOnInit() {
  }

  openArticle(inArticle:ArticleModel){
    this.router.navigate(['/articles/view', inArticle.key])
  }

  editArticle(inArticle:ArticleModel){
    this.router.navigate(['/articles/edit', inArticle.key])
  }

}
