import { Component, OnInit, OnDestroy } from '@angular/core';
import { ArticleService } from '../../services/article.service';
import { ArticleModel } from '../../models/article-model';
import { EditorComponent } from '../editor/editor.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SuredialogComponent } from '../dialogs/suredialog/suredialog.component';
import { SettingsService } from '../../services/settings.service';
import { ChannelDefModel } from '../../models/settings/channel-def-model';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-articleloader',
  templateUrl: './articleloader.component.html',
  styleUrls: ['./articleloader.component.css']
})
export class ArticleloaderComponent implements OnInit, OnDestroy {
  public articleModel: ArticleModel = new ArticleModel();
  public _channelDefs: Array<ChannelDefModel> = [];
  public channelsObservable: Observable<Array<ChannelDefModel>>;
  private subscription;
  private _signedInUser: firebase.User;

  constructor(
    private settingsService: SettingsService,
    private articleService: ArticleService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    public snackbar: MatSnackBar) {

    // this.subscription = this.settingsService.retrieveChannelDefs().subscribe((inChannelDefs: Array<ChannelDefModel>) => {
    //   this._channelDefs = inChannelDefs;
    // });

    authService.getLoggedInUser().subscribe(inUser => {
      this._signedInUser = inUser;
    });
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.articleService.retrieveArticle(params['articleKey']).then((result: ArticleModel) => {
        this.articleModel = result;
      });
    });
  }

  ngOnDestroy() {
   // this.subscription.unsubscribe();
  }

  /**
   *  saveArticle()
   *
   * Calls the article service and saves an article, also opens a dialog
   * @param inArticleModel
   *
   */
  public saveArticle(inArticleModel: ArticleModel) {
    this.articleService.storeArticle(inArticleModel, this._channelDefs, this._signedInUser.email);

    this.openSnackbar('Article saved', '');

  }

  /**
   * publishArticle()
   *
   * Publishes an article
   * @param inArticleModel
   *
   */
  public publishArticle(inArticleModel: ArticleModel) {
    // TODO: publish article, aka put in publishlist for cloud function for API

    // And also save the article
    this.articleService.storeArticle(inArticleModel, this._channelDefs, this._signedInUser.email);
    //console.log(inArticleModel.published);
    if (inArticleModel.published) {
      this.openSnackbar('Article published', '');
    } else {
      this.openSnackbar('Article unpublished', '');
    }

  }

  /**
   * cancelArticle()
   *
   * Cancels an article-edit (just goes back to the article list without saving)
   * @param inArticleModel
   *
   */
  public cancelArticle(inArticleModel: ArticleModel) {
    // TODO: dialog to make sure the editor wants to go back without saving
    this.router.navigate(['/articles']);
  }

  /**
   * deleteArticle()
   * @param inArticleModel
   *
   * Deletes an article
   */
  public deleteArticle(inArticleModel: ArticleModel) {
    const dialogRef = this.dialog.open(SuredialogComponent, {
      autoFocus: false,
      data: {
        title: 'Delete article',
        body: 'Are you sure you want to delete this article?',
        declineText: 'No',
        affirmativeText: 'Yes',
        action: false
      }
    });

    // Gets the result from the dialogcomponent
    // If user action is confirming, delete the article
    // Else do nothing
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        if (inArticleModel.key !== '') {
          this.articleService.removeArticle(inArticleModel);
        }
        this.router.navigate(['/articles']);
        this.openSnackbar('Article deleted', '');
      }

    });

  }

  public viewArticle(inArticleModel: ArticleModel) {
    this.articleService.storeArticle(inArticleModel, this._channelDefs, this._signedInUser.email);
    this.router.navigate(['articles/view/', inArticleModel.key]);
  }

  openSnackbar(message: string, action: string) {
    this.snackbar.open(message, action, {
      duration: 2000,
    });
  }

}
