import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, HostListener } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask, AngularFireStorageReference } from '@angular/fire/storage';
import { Observable, Subscription } from 'rxjs';
import { finalize, first } from 'rxjs/operators';
import { ArticleModel } from '../../models/article-model';
import { ArticleService } from '../../services/article.service';
import { Router } from '@angular/router';
import { RolesService } from '../../services/roles.service';
import { MatDialog } from '../../../../node_modules/@angular/material';
import { EditorModel } from '../../models/editor-model';
import { SettingsService } from '../../services/settings.service';


@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css']
})
export class EditorComponent implements OnInit, OnDestroy {


  public froalaOptions: Object = {};
  public isEditor = false;
  public editor;
  public editorObsAr = new Observable<EditorModel[]>();
  public editorObs = new Observable<EditorModel[]>();
  public editorChannels = [];
  public channels = [];

  private imagesToDelete = [];
  private subscription;
  private channelSubscription;


  @Input() public inputArticleModel: ArticleModel;
  @Input() public inputAllChannelDefs = [];

  @Output() outputArticleModel: ArticleModel;
  @Output() saveArticleEmitter = new EventEmitter<ArticleModel>();
  @Output() publishArticleEmitter = new EventEmitter<ArticleModel>();
  @Output() cancelArticleEmitter = new EventEmitter<ArticleModel>();
  @Output() deleteArticleEmitter = new EventEmitter<ArticleModel>();
  @Output() viewArticleEmitter = new EventEmitter<ArticleModel>();


  constructor(
    private afStorage: AngularFireStorage,
    private articleService: ArticleService,
    private router: Router,
    private rolesService: RolesService,
    private dialog: MatDialog,
    private settingsService: SettingsService) {
    const me = this;

    this.isEditor = rolesService.isEditor;
    // The configuration options for Froala are configured trough an object
    this.froalaOptions = {
      key: '9B3C3E3A4B-16D4E3C2C1C3H2C1B10C2C2pdmlC-7I1C-22D-16==',
      placeholderText: 'Write your content here',
      charCounterCount: false,
      toolbarSticky: false,
      events: {
        // This is a hook to customize image uploading from Froala 
        'froalaEditor.image.beforeUpload': function (e, editor, files) {
         
          if (files.length) {
            me.setImage(editor, files);
          }
          return false;
        },
        'froalaEditor.image.removed': function (e, editor, $img) {
          const src = $img.attr('src');
          // this.imagesToDelete.push(src);
          me.removeImage(src);
          // editor.undo.saveStep();

        },
        'froalaEditor.image.inserted': function (e, editor, $img, response) {
        },
        'froalaEditor.commands.after': function (e, editor, cmd, param1, param2) {
        }
      }
    };
  }

  ngOnInit() {


    this.subscription = this.rolesService.getLoggedInEditor().subscribe(result => {
      this.editor = result[0];
      this.editorChannels = result[0].channels;

      this.channelSubscription = this.settingsService.retrieveChannelDefs().subscribe(channelDefs => {
        this.inputAllChannelDefs = channelDefs;

        if (result[0].allChannels === true) {
          this.inputAllChannelDefs.forEach(channel => {
            this.channels.push(channel.key);
          });
        } else {
          this.channels = result[0].channels;
        }

      });
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.channelSubscription.unsubscribe();
  }

  saveArticle() {
    this.saveArticleEmitter.emit(this.inputArticleModel);
    // this.deleteImagesOnSave();    
  }
  publishArticle() {
    this.inputArticleModel.published = !this.inputArticleModel.published;
    this.publishArticleEmitter.emit(this.inputArticleModel);

  }
  cancelArticle() {
    this.cancelArticleEmitter.emit(this.inputArticleModel);
  }

  deleteArticle() {
    this.deleteArticleEmitter.emit(this.inputArticleModel);
  }

  viewArticle() {
    this.viewArticleEmitter.emit(this.inputArticleModel);

  }

  /**
   * Uploads image to firestore
   * Inserts the image back in the froala editor 
   * Adds image to the Articlemodel imageUrls array
   * 
   * @param inTask 
   * @param inRef 
   * @param inEditor 
   */
  setImage(inEditor: any, inFiles: any) {
    // The randomId is generated for the file name
    const randomId = Math.random().toString(36).substring(2);

    // Filepath is needed for the save location of Firestore
    const filePath = 'articleImages/' + randomId;
    const ref = this.afStorage.ref(filePath);

    // console.log('uploaded', filePath);

    // File is uploaded to Firestore
    const task = this.afStorage.upload(filePath, inFiles[0]);

    // When the upload is done (finalized), the image is inserted in the editor via the download url of firestore
    let downloadUrlsubscription = new Subscription;
    task.snapshotChanges().pipe(
      finalize(() => downloadUrlsubscription = ref.getDownloadURL().subscribe(result => {
        inEditor.image.insert(result, null, null, inEditor.image.get());
        inEditor.undo.saveStep();
        this.inputArticleModel.addImageUrl(result);
        inEditor.undo.reset();
        //   console.log('main image ref upload', this.inputArticleModel.mainImageRef);
        //   console.log('image urls upload', this.inputArticleModel.imageUrls);
      })))
      .subscribe(() => {
        downloadUrlsubscription.unsubscribe();
      });

  }

  // async uploadImage(inFiles: File[]) {
  //   const randomId = Math.random().toString(36).substring(2);
  //   const filePath = 'articleImages/' + randomId;
  //   const ref = this.afStorage.ref(filePath);
  //   const task = this.afStorage.upload(filePath, inFiles[0]);
  //   try {

  //     const downloadUrl = await task.snapshotChanges().pipe(
  //       finalize(() => ref.getDownloadURL().pipe(first()).toPromise()),
  //       first()
  //     ).toPromise();

  //     console.log(downloadUrl);
  //   } catch (error) {

  //   }

  // }

  /**
   * removeImage()
   * Extracts the right reference and removes an image from firestore
   * removes the image from the imageurl arrays of the Article model
   * 
   * @param src 
   * 
   */
  removeImage(inSrc: string) {
    const reference = inSrc.split('%2F').pop().split('?').shift();
    this.inputArticleModel.removeImageUrl(inSrc);

    // this.afStorage.ref('articleImages/' + reference).delete();
    // console.log('remove from imageUrls', reference);
    //   console.log('main image ref', this.inputArticleModel.mainImageRef);
    // console.log('imageurls', this.inputArticleModel.imageUrls);
  }

  /**
   * deleteImagesOnSave()
   * 
   * Deletes all images in the imagestodelete array.
   * This array is filled when an image is deleted from the editor
   * 
   */
  deleteImagesOnSave() {
    this.imagesToDelete.forEach(src => {
      const reference = src.split('%2F').pop().split('?').shift();
      this.afStorage.ref('articleImages/' + reference).delete();
      //  console.log('deleted', reference);
    });
  }

  /**
   * setMainImageRef()
   * 
   * Sets the mainImageReference
   * This is used for selecting the main image in the html component
   */
  selectMainImage(inSrc: string) {
    this.inputArticleModel.mainImageRef = inSrc;

  }

  /**
   * addToArticleChannels()
   * 
   * Adds a channel to the article model
   * 
   * @param channel 
   */
  addToArticleChannels(channel: string) {
    this.inputArticleModel.addChannel(channel);
  }

  /**
   * removeFromArticleChannels()
   * 
   * Removes an article from the article model 
   * @param inChannelKey 
   * 
   */
  removeFromArticleChannels(inChannelKey: string) {
    this.inputArticleModel.removeChannel(inChannelKey);
  }

 
  


}
