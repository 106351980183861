import {Component, Input, OnInit} from '@angular/core';
import {EditorModel} from '../../../../models/editor-model';
import {ArticleModel} from '../../../../models/article-model';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {RolesService} from '../../../../services/roles.service';
import {ChannelDefModel} from '../../../../models/settings/channel-def-model';
import {SettingsService} from '../../../../services/settings.service';
import {ErrorStateMatcher} from '@angular/material/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-editor-editor',
  templateUrl: './editor-editor.component.html',
  styleUrls: ['./editor-editor.component.css']
})
export class EditorEditorComponent implements OnInit {
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  matcher = new MyErrorStateMatcher();

  public editor: EditorModel = new EditorModel();
  public channelDefs: Array<ChannelDefModel> = [];

  private subscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private rolesService: RolesService,
    private settingsService: SettingsService
  ) { }

  ngOnInit() {
    // Gets the key from the route parameters
    this.subscription = this.route.params.subscribe(params => {
      this.rolesService.retrieveEditor(params['key']).then((result: EditorModel) => {
        this.editor = result;
      });
    });

    this.subscription = this.settingsService.retrieveChannelDefs().subscribe((inChannelDefs: Array<ChannelDefModel>) => {
      this.channelDefs = inChannelDefs;
    });
  }

  public removeFromEditorChannels(inChannelDefKey: string) {
    this.editor.removeChannel(inChannelDefKey);
  }

  public addToEditorChannels(inChannelDef: ChannelDefModel) {
    this.editor.addChannel(inChannelDef.key);
  }

  public saveEditor() {
    this.rolesService.storeEditor(this.editor);
    this.router.navigate(['admin']);
  }

  public handleCancel() {
    this.router.navigate(['admin']);
  }

  public HandleDelete() {
    if (this.editor.hasKey()) {
      this.rolesService.removeEditor(this.editor);
      this.router.navigate(['admin']);
    }
  }
}
