import { Component, OnInit, Input, SimpleChanges, ElementRef } from '@angular/core';
import { ArticleModel } from 'src/app/models/article-model';
import { ChannelDefModel } from 'src/app/models/settings/channel-def-model';
import { Router } from '@angular/router';
import { RolesService } from 'src/app/services/roles.service';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ViewportScroller } from '@angular/common';
import { EditorModel } from 'src/app/models/editor-model';

@Component({
  selector: 'app-deluxeview',
  templateUrl: './deluxeview.component.html',
  styleUrls: ['./deluxeview.component.css']
})
export class DeluxeviewComponent implements OnInit {
  @Input() articles: ArticleModel[];
  @Input() articles$: Observable<ArticleModel[]>;
  public headArticle$: Observable<ArticleModel>;
  public publishedArticles$: Observable<ArticleModel[]>;
  @Input() channelDefs: ChannelDefModel[];
  @Input() isEditor = false;
  editor$: Observable<EditorModel>;

  mayEditByChannel$: Observable<boolean>;


  constructor(private router: Router, private rolesService: RolesService, private viewportScroller: ViewportScroller) { }

  ngOnInit() {
    this.headArticle$ = this.articles$.pipe(
      map(articles => {
        return articles.find(article => article.published && article.mainImageRef);
      })
    )
    this.publishedArticles$ = this.articles$.pipe(
      map(articles => {
        return articles.filter(article => article.published && article.mainImageRef);
      })
    )

    this.editor$ = this.rolesService.getLoggedInEditor().pipe(
      map(editors => editors[0])
    );
  }


  openArticle(inArticle: ArticleModel) {
    this.router.navigate(['/articles/view', inArticle.key])
  }

  editArticle(inArticle: ArticleModel) {
    this.router.navigate(['/articles/edit', inArticle.key])
  }

  navigateToAll($inElement) {


    $inElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });

  }

}
