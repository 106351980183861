import { Component, OnInit } from '@angular/core';
import {ChannelDefModel} from '../../../models/settings/channel-def-model';
import {Router} from '@angular/router';
import {SettingsService} from '../../../services/settings.service';

@Component({
  selector: 'app-channel-list',
  templateUrl: './channel-list.component.html',
  styleUrls: ['./channel-list.component.css']
})
export class ChannelListComponent implements OnInit {
  public channels: Array<ChannelDefModel> = [];

  constructor(
    private settingsService: SettingsService,
    private router: Router
  ) {
    this.settingsService.retrieveChannelDefs().subscribe((inList: Array<ChannelDefModel>) => {
      this.channels = inList;
    });
  }

  ngOnInit() {
  }

  public editChannel(inChannel: ChannelDefModel) {
    this.router.navigate(['admin/editchannel', inChannel.key]);
  }

  public newChannel() {
    this.router.navigate(['admin/editchannel', '']);
  }
}
