import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SettingsService} from '../../../../services/settings.service';
import {ApiKeyModel} from '../../../../models/api-key-model';
import {Subscription} from 'rxjs';
import {DomainDefModel} from '../../../../models/settings/domain-def-model';

@Component({
  selector: 'app-domain-editor',
  templateUrl: './domain-editor.component.html',
  styleUrls: ['./domain-editor.component.css']
})
export class DomainEditorComponent implements OnInit {
  public domain: DomainDefModel = new DomainDefModel();
  private subscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private settingsService: SettingsService) {

    // Gets the key from the route parameters
    this.subscription = this.route.params.subscribe(params => {
      this.settingsService.retrieveSupportedDomain(params['key']).then((result: DomainDefModel) => {
    //    console.log('DomainEditorComponent - domain: ' + result.domain);
        this.domain = result;
      });
    });
  }

  ngOnInit() {
  }

  public saveDomain() {
    this.settingsService.storeDomain(this.domain);
    this.router.navigate(['sysadmin']);
  }

  public handleCancel() {
    this.router.navigate(['sysadmin']);
  }

  public HandleDelete() {
    if (this.domain.hasKey()) {
      this.settingsService.removeDomain(this.domain);
      this.router.navigate(['sysadmin']);
    }
  }

}
