/**
 * ArticleListViewComponent
 *
 * Displays a collection of articles in various ways
 *
 * @Input()  canEdit:    boolean                      Does the current user have edit privileges
 * @Output() open:       EventEmitter<ArticleModel>   Emitted when a user clicks on the title to open the article
 * @Output() edit:       EventEmitter<ArticleModel>   Emitted when a user/editor clicks on the edit button to edit the article
 */
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription, Observable, of, Subject, combineLatest, forkJoin } from 'rxjs';
import { ArticleModel } from '../../models/article-model';
import { ArticleService } from '../../services/article.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SettingsService } from '../../services/settings.service';
import { ChannelDefModel } from '../../models/settings/channel-def-model';
import { RolesService } from '../../services/roles.service';
import { Route } from '../../../../node_modules/@angular/compiler/src/core';
import { catchError, first, debounceTime, distinctUntilChanged, map, flatMap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-article-list-view',
  templateUrl: './article-list-view.component.html',
  styleUrls: ['./article-list-view.component.css']
})
export class ArticleListViewComponent implements OnInit, OnDestroy {
  @Output() open: EventEmitter<ArticleModel> = new EventEmitter<ArticleModel>();
  @Output() edit: EventEmitter<ArticleModel> = new EventEmitter<ArticleModel>();

  private articleListSubscription: Subscription;
  private channelDefSubscription: Subscription;
  private queryParamsSubscription: Subscription;

  private alphaOrder = false;
  private pubDateOrder = false;
  private pubOrder = false;

  public listExpanded = false;
  public articles: Array<ArticleModel> = [];
  public filteredArticles: Array<ArticleModel> = [];
  public channelDefs: Array<ChannelDefModel> = [];
  public selected = [];
  public saveSearchString = '';

  public articles$: Observable<ArticleModel[]>;
  public channelDefs$: Observable<ChannelDefModel[]>;
  public publishedArticles$: Observable<ArticleModel[]>;
  public isError$: Subject<boolean>;
  queryField: FormControl = new FormControl();
  public loadingFilter = false;

  constructor(
    private articleService: ArticleService,
    private settingsService: SettingsService,
    public rolesService: RolesService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    // Subscribe to the ChannelDefs


    this.setArticles();
    // this.setPreselectedFilter(); 
    this.articles$ = this.articleService.getArticlesFromIndex(null).pipe(
      map(articles => articles.sort(this.sortPublicationDefault)),
      catchError(error => {
        this.isError$.next(true);
        return of(null);
      })
    );

    this.publishedArticles$ = this.articleService.getArticlesFromIndex(null).pipe(
      map(articles => {
        const ta: ArticleModel[] = [];
        if (articles.some(article => article.published)) {
          ta.push;
        }
        return ta;
      })
    )

  
    this.channelDefs$ = this.settingsService.retrieveChannelDefs();

    this.setChannelOnInit();

  }



  ngOnDestroy() {
    // Unsubscribe on component destroy
    this.articleListSubscription.unsubscribe();
    // this.channelDefSubscription.unsubscribe();
    // this.queryParamsSubscription.unsubscribe();
  }

  /**
    * This method is called in ngOninit
    * 
    * Retrieves all articles and sets them to a two global variables; original article list and filtered article lists
    * This is needed for filtering
    * 
    * setChannelDefs()
    */
  setArticles() {
    // Subscribe to the Article list
    this.articleListSubscription = this.articleService.getArticlesFromIndex(null).subscribe(

      (inArticles: Array<ArticleModel>) => {
        this.articles = inArticles;
        this.filteredArticles = inArticles;
        this.filteredArticles.sort(this.sortPublicationDefault);
        // this.setChannelDefs();
      }
    );
  }

  // searchArticles(inQuery: string) {
  //   this.articleService.searchArticle(inQuery).subscribe(result => {
  //     console.log(result);
  //   })
  // }

  async loadMoreArticles(inArticles: ArticleModel[]) {
    const lastArticle: ArticleModel = inArticles.slice(-1)[0];
    const moreArticles = await this.articleService.getArticlesFromIndex(lastArticle).pipe(first()).toPromise();

    moreArticles.forEach(article => {
      if (!inArticles.some(ar => ar.key === article.key)) {
        inArticles.push(article);
      }
    })

    inArticles.sort(this.sortPublicationDefault)
  }

  /**
   * Presets the filters for the article list
   * Checks if the requested parameter is an  array
   * setPreselectedFilter()
   */
  setChannelOnInit() {
    const theParams$ = this.route.queryParams;
    const channelDefs$ = this.settingsService.retrieveChannelDefs();

    combineLatest(theParams$, channelDefs$, (params, channelDefs) => {
      const channelLabels = params['channels'];

      if (channelLabels) {
        this.selected = this.getLabelsFromKey(channelLabels, channelDefs);
        this.filterList(this.selected);
      }
    }).pipe(first()).toPromise()
  }

  getLabelsFromKey(inChannelLabels: string[], inChannelDefs: ChannelDefModel[]) {
    const preselect = [];
    if (Array.isArray(inChannelLabels)) {
      inChannelDefs.forEach(channelDef => {
        if (inChannelLabels.indexOf(channelDef.label) >= 0) {
          preselect.push(channelDef.key);
        }

      });
    } else {
      inChannelDefs.forEach(channelDef => {
        if (channelDef.label === inChannelLabels) {
          preselect.push(channelDef.key);
        }
      });
    }

    return preselect
  }


  openArticle(inArticle: ArticleModel) {
    this.router.navigate(['/articles/view', inArticle.key])
  }

  newArticle() {
    // console.log('newarticle');
    this.router.navigate(['/articles/edit', '']);
  }

  /**
   * 
   * Sorts the article alphabetically
   * 
   * sortAlphabetically()
   */
  sortAlphabetically() {
    this.alphaOrder = !this.alphaOrder;
    this.filteredArticles.sort((a, b) => {
      if (this.alphaOrder) {
        if (a.title.toLowerCase() < b.title.toLowerCase()) { return -1; }
        if (a.title.toLowerCase() > b.title.toLowerCase()) { return 1; }
      } else {
        if (a.title.toLowerCase() < b.title.toLowerCase()) { return 1; }
        if (a.title.toLowerCase() > b.title.toLowerCase()) { return -1; }
      }
      return 0;
    });

  }

  /**
   * 
   * Sorts the article array on the last updated date of an article
   * 
   * sortPublicationdate()
   */
  sortPublicationdate() {
    this.pubDateOrder = !this.pubDateOrder;
    this.filteredArticles.sort((a, b) => {
      if (this.pubDateOrder) {
        if (a.lastUpdateDateInMs < b.lastUpdateDateInMs) { return -1; }
        if (a.lastUpdateDateInMs > b.lastUpdateDateInMs) { return 1; }
      } else {
        if (a.lastUpdateDateInMs < b.lastUpdateDateInMs) { return 1; }
        if (a.lastUpdateDateInMs > b.lastUpdateDateInMs) { return -1; }
      }
      return 0;
    });

  }

  sortPublicationDefault(a: ArticleModel, b: ArticleModel) {
    if (a.lastUpdateDateInMs < b.lastUpdateDateInMs) { return 1; }
    if (a.lastUpdateDateInMs > b.lastUpdateDateInMs) { return -1; }
    return 0;
  }

  /**
   * 
   * Sorts the article array on article published
   * 
   * sortPublished()
   */
  sortPublished() {
    this.pubOrder = !this.pubOrder;
    this.filteredArticles.sort((a, b) => {
      if (this.pubOrder) {
        if (a.published < b.published) { return 1; }
        if (a.published > b.published) { return -1; }
      } else {
        if (a.published < b.published) { return -1; }
        if (a.published > b.published) { return 1; }
      }
      return 0;
    });

  }

  /**
   * 
   * This methods filters the article array and calls two filter methods: filterSearchList() and filterOnChannel()
   * This is needed so the filtered list can be filtered on search AND channels
   * 
   * @param searchString 
   * 
   * FilterList()
   */
  // filterList(searchString: string) {
  //   let tempArticles = [];
  //   this.saveSearchString = searchString;

  //   tempArticles = this.filterSearchList(this.articles, this.saveSearchString);
  //   // console.log(this.articles);
  //   this.filteredArticles = this.filterOnChannel(this.selected, tempArticles);

  // }

  async filterList(inSelected) {
    this.loadingFilter = true;
    this.articles$ = this.articleService.filterOnChannels(inSelected).pipe(
      map(articles => articles.sort(this.sortPublicationDefault))
    );

    this.loadingFilter =false;
  }


  /**
   * This method filters checks if the string inputted by the users is equal to an article title in the array
   * If so it returns the searched article
   * 
   * @param inArticles 
   * @param searchString 
   */
  filterSearchList(inArticles: Array<ArticleModel>, searchString: any) {
    return inArticles.filter(article => {
      return article.title.toLowerCase().includes(searchString.toLowerCase());
    });
  }

  /**
   * 
   * This method checks if the by user selected channel is present in the array of articles
   * If so, it returns the articles that are equal to this selection
   * 
   * @param selectedOptions 
   * @param inArticles 
   * 
   * filterOnChannel()
   */
  filterOnChannel(selectedOptions, inArticles: Array<ArticleModel>) {
    // console.log(selectedOptions);
    const newArticles = [];
    if (selectedOptions.length <= 0) {
      return inArticles;
    } else {
      inArticles.forEach(article => {
        selectedOptions.forEach(option => {
          if (article.channels.indexOf(option) >= 0) {
            if (newArticles.indexOf(article) < 0) {
              newArticles.push(article);
            }
          }
        });
      });
      return newArticles;
    }
  }






}
