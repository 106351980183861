import { Component} from '@angular/core';
import {RoleModel} from '../../../models/role-model';
import {Router} from '@angular/router';
import {RolesService} from '../../../services/roles.service';
import {EditorModel} from '../../../models/editor-model';

@Component({
  selector: 'app-sysadmin-list',
  templateUrl: './sysadmin-list.component.html',
  styleUrls: ['./sysadmin-list.component.css']
})
export class SysadminListComponent {
  public sysadmins: Array<RoleModel> = [];

  constructor(
    private rolesService: RolesService,
    private router: Router
  ) {
    this.rolesService.getSysAdminList().subscribe((sysadminList: Array<RoleModel>) => {
    this.sysadmins = sysadminList;
  });
  }


  public editSysadmin(inSysadmin: RoleModel) {
    this.router.navigate(['sysadmin/editsysadmin', inSysadmin.key]);
  }

  public newSysadmin() {
    this.router.navigate(['sysadmin/editsysadmin', '']);
  }
}
