import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ArticleService } from 'src/app/services/article.service';
import { Observable } from 'rxjs';
import { ArticleModel } from 'src/app/models/article-model';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-article-search',
  templateUrl: './article-search.component.html',
  styleUrls: ['./article-search.component.css']
})
export class ArticleSearchComponent implements OnInit {
  public query: string = '';
  public queryLoading = false;
  public searchedArticles: ArticleModel[]
  @Output() itemClicked: EventEmitter<ArticleModel> = new EventEmitter();
  constructor(private articleService: ArticleService, private router: Router) { }

  ngOnInit() {
  }

  async searchArticle() {
    try {
      this.queryLoading = true;
      this.searchedArticles = await this.articleService.queryArticles(this.query).pipe(first()).toPromise();

    } catch (error) {

    } finally {
      this.queryLoading = false;
    }
  }

  openArticle(inArticle: ArticleModel) {
    this.router.navigate(['/articles/view', inArticle.key])
  }
  
  onItemClick(inArticle:ArticleModel){
    this.itemClicked.emit(inArticle);
  }

}
